<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerAddress !== null"
            :data="customerAddress"
            parseSalutation
            title="Kundeninformationen"
          />
          <div v-if="contractId">
            <DisplayContainer>
              <template v-slot:label>
                Vertrag <strong>{{ contractId }}</strong> Produktinformationen
              </template>
              <AllOrderedProductsTable :allProducts="contractProducts" />
            </DisplayContainer>
            <DisplayContainer :label="'Komfortinstallations Datum'">
              <DatePicker
                label="Gewünschtes Komfortinstallationsdatum"
                :value="plannedActivationDateGUI"
                disableWeekend
                disableHolidays
                :minDate="plannedActivationDate"
                :errorMessage="dateErrorMessage"
                :disabled="!claimed"
                @input="updatePlannedActivationDate($event)"
              />
            </DisplayContainer>
            <DisplayContainer
              :label="'Anmerkungen zum Komfortinstallationstermin'"
            >
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Anmerkungen zur Komfortinstallation"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="completeTask(false)"
        >
          Komfortinstallationstermin bestätigen
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="!claimed"
          @click="cancellationDialog = !cancellationDialog"
        >
          Stornieren
        </v-btn>
      </div>
      <v-dialog v-model="cancellationDialog" width="20vw">
        <v-card>
          <br />
          <v-card-text>
            <p>Komfort Installation stornieren?</p>
            <v-spacer></v-spacer>
            <div class="dialog-buttons">
              <v-btn
                small
                color="secondary"
                @click="cancellationDialog = !cancellationDialog"
              >
                Abbrechen
              </v-btn>
              <v-btn
                small
                color="primary"
                @click="completeTask(true)"
              >
                Ja
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import TextLink from '@/components/elements/TextLink.vue';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionComfortInstallation',
  components: {
    TaskBlocker,
    Loader,
    CustomerDetails,
    DatePicker,
    NotificationObject,
    DisplayContainer,
    AllOrderedProductsTable,
    TextLink,
    ErrorMessageBuilder
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      cancellationDialog: false,
      isLoading: false,
      customerAddress: null,
      contractId: null,
      customerId: null,
      memo: null,
      plannedActivationDate: null,
      plannedActivationDateGUI: null,
      contractProducts: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.inputIsValid(this.memo);
    },
    dateErrorMessage() {
      if (
        LocalDate.parse(this.plannedActivationDate).isBefore(LocalDate.now())
      ) {
        return 'Der geplante Schalttermin liegt in der Vergangenheit. Es muss ein neues Datum für die Komfortinstallation ausgewählt werden.';
      } else {
        return null;
      }
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    updatePlannedActivationDate: function (newDate) {
      this.plannedActivationDateGUI = newDate;
      this.$emit('onDataChange', this.plannedActivationDateGUI);
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          const formatter = DateTimeFormatter.ofPattern(
            "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
          );
          // Prefer local customer data to process customer data
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          this.loadContractProducts(this.contractId);
          this.plannedActivationDate = LocalDate.parse(
            resp.data.plannedActivationDate.value,
            formatter
          ).toString();
          if (resp.data.plannedActivationDateGUI !== undefined) {
            this.plannedActivationDateGUI =
              resp.data.plannedActivationDateGUI.value;
          } else {
            this.plannedActivationDateGUI = this.plannedActivationDate;
          }
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }

          return resp;
        })
        .then((response) => {
          return this.fetchAddressInfo(this.contractId);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`)
        .then((res) => {
          this.customerAddress = { ...res.data, customerId: this.customerId };
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        );
    },
    loadContractProducts: function (contractId) {
      HTTP.get(`/products/contract/all/${contractId}`)
        .then((resp) => {
          this.contractProducts = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.productDataLoading = false;
        });
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          },
          plannedActivationDateGUI: {
            value: this.plannedActivationDateGUI,
            type: 'String'
          }
        }
      });
    },
    completeTask: function (cancellation) {
      HTTP.post(
        `/orderTask/comfortInstallation/${this.data.id}`,
        {
          plannedActivationDateString: this.plannedActivationDateGUI,
          memo: this.memo,
          assignee: this.data.assignee,
          cancelled: cancellation
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 60;
    }
  }
};
</script>

<style scoped>
.dialog-buttons .v-btn {
  margin-right: 5px;
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
