<template>
  <div class="prozess-wrapper">
    <div>
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>

      <OrderProcessDialogue
        v-if="!isLoading && previousDataSelection"
        :disabled="!claimed"
        :previousDataSelection="previousDataSelection"
        @onClose="
          {
            $store.commit('triggerTasksRefresh');
          }
        "
      />
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';

import OrderProcessDialogue from '@/components/orderEntry/orderCreation/OrderProcessDialogue.vue';

import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import Loader from '@/components/Loader.vue';
import { defaultValues as defaultValuesCustomerDataSelection } from '@/components/orderEntry/orderCreation/CustomerDataSelection.vue';
import { defaultValues as defaultValuesServiceOrderOptions } from '@/components/orderEntry/orderCreation/ServiceOrderOptionsSelection.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionFinalizeOrder',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    OrderProcessDialogue,
    Loader,
    DataDisplay: () => import('@/components/elements/DataDisplay.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    previousDataSelection: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  created() {
    this.loadTaskData(this.data.id);
  },
  methods: {
    loadTaskData: async function (id) {
      const resp = await HTTP.get(`/task/${id}/variables`).catch((err) => {
        console.log(err);
        this.showMessage(
          'error',
          'Die Prozessdaten konnten nicht geladen werden!'
        );
      });
      this.previousDataSelection = await this.buildContext(
        id,
        resp.data.order.value
      );
    },
    async buildContext(taskId, orderDto) {
      const orderType = orderDto.orderType;

      const defaultCustomerData = lodash.cloneDeep(
        defaultValuesCustomerDataSelection
      );

      let customerData = null;
      let serviceOrderProducts = null;
      let constructionCostProduct = null;
      let serviceOrderOptions = null;
      let serviceOrderUploadedFiles = null;
      let houseConnectionOrderUploadedFiles = null;

      const serviceOrderDto = orderDto.serviceOrder;
      const houseConnectionOrderDto = orderDto.houseConnectionOrder;

      const orderDate = orderDto.orderDate ? orderDto.orderDate : null;
      if (orderType === 'SERVICE') {
        customerData = lodash.assign(
          defaultCustomerData,
          this.buildCustomerDataServiceOrder(serviceOrderDto)
        );
      } else if (orderType === 'HOUSECONNECTION') {
        customerData = lodash.assign(
          defaultCustomerData,
          this.buildCustomerDataHouseConnectionOrder(houseConnectionOrderDto)
        );
      } else {
        customerData = lodash.assign(
          {},
          defaultCustomerData,
          this.buildCustomerDataServiceOrder(serviceOrderDto),
          this.buildCustomerDataHouseConnectionOrder(houseConnectionOrderDto)
        );
      }

      if (
        orderType === 'SERVICE' ||
        orderType === 'HOUSECONNECTION_WITH_SERVICE'
      ) {
        if (serviceOrderDto && serviceOrderDto.orderedProducts) {
          serviceOrderProducts = await this.buildServiceOrderProducts(
            taskId,
            serviceOrderDto.orderedProducts
          ).then((products) => (serviceOrderProducts = products));
        } else {
          serviceOrderProducts = null;
        }

        serviceOrderOptions = serviceOrderDto
          ? this.buildServiceOrderOptions(serviceOrderDto)
          : null;
        serviceOrderUploadedFiles =
          serviceOrderDto.uploadedDocuments &&
          serviceOrderDto.uploadedDocuments.length !== 0
            ? await this.buildFiles(serviceOrderDto.uploadedDocuments)
            : null;
      }
      if (
        orderType === 'HOUSECONNECTION' ||
        orderType === 'HOUSECONNECTION_WITH_SERVICE'
      ) {
        if (
          houseConnectionOrderDto &&
          houseConnectionOrderDto.constructionCostProductId
        ) {
          constructionCostProduct =
            await this.buildHouseConnectionOrderProducts(taskId).then(
              (product) => (constructionCostProduct = product)
            );
        } else {
          constructionCostProduct = null;
        }

        houseConnectionOrderUploadedFiles =
          houseConnectionOrderDto.uploadedDocuments &&
          houseConnectionOrderDto.uploadedDocuments.length !== 0
            ? await this.buildFiles(houseConnectionOrderDto.uploadedDocuments)
            : null;
      }
      return {
        taskId: taskId,
        selectedOrderType: orderType,
        orderDate: orderDate,
        selectedCustomerData: customerData,
        selectedServiceOrderProducts: serviceOrderProducts,
        selectedHouseConnectionOrderProduct: constructionCostProduct,
        selectedServiceOrderOptions: serviceOrderOptions,
        uploadedFilesServiceOrder: serviceOrderUploadedFiles,
        uploadedFilesHouseConnectionOrder: houseConnectionOrderUploadedFiles,
        fromHumanTask: true
      };
    },
    buildCustomerDataServiceOrder(serviceOrderDto) {
      const customerData = {};

      const installationAddress = serviceOrderDto.installationAddress;
      const invoiceAddress = serviceOrderDto.invoiceAddress;
      const deliveryAddress = serviceOrderDto.deliveryAddress;
      const contactAddress = serviceOrderDto.additionalContactAddress;
      const customerInformation =
        this.buildCustomerInformation(installationAddress);
      const customerDataInstallationAddress =
        this.buildAddressLocationInformation(installationAddress);
      let deviatingInvoiceAddressIsSelected = null;
      let customerDataInvoiceAddress = null;
      let contactAddressIsSelected = false;
      let customerDataContactAddress = null;
      let deviatingDeliveryAddressIsSelected = null;
      let customerDataDeliveryAddress = null;

      const sepaMandate = serviceOrderDto.sepaMandate;

      if (
        deliveryAddress &&
        lodash.isEqual(installationAddress, deliveryAddress) === false
      ) {
        deviatingDeliveryAddressIsSelected = true;
        customerDataDeliveryAddress =
          this.buildAddressInformation(deliveryAddress);
      } else {
        deviatingDeliveryAddressIsSelected = false;
        customerDataDeliveryAddress = null;
      }

      if (
        invoiceAddress &&
        lodash.isEqual(installationAddress, invoiceAddress) === false
      ) {
        deviatingInvoiceAddressIsSelected = true;
        customerDataInvoiceAddress =
          this.buildAddressInformation(invoiceAddress);
      } else {
        deviatingInvoiceAddressIsSelected = false;
        customerDataInvoiceAddress = null;
      }
      if (
        contactAddress &&
        lodash.isEqual(installationAddress, contactAddress) === false
      ) {
        contactAddressIsSelected = true;
        customerDataContactAddress =
          this.buildAddressInformation(contactAddress);
      } else {
        contactAddressIsSelected = false;
        customerDataContactAddress = null;
      }

      customerData.customerInformation = customerInformation;
      customerData.installationAddress = customerDataInstallationAddress;
      customerData.sepaMandate = sepaMandate;
      customerData.invoiceAddressIsSelected = deviatingInvoiceAddressIsSelected;
      customerData.invoiceAddress = customerDataInvoiceAddress;
      customerData.contactAddressIsSelected = contactAddressIsSelected;
      customerData.contactAddress = customerDataContactAddress;
      customerData.deviatingDeliveryAddressIsSelected =
        deviatingDeliveryAddressIsSelected;
      customerData.deliveryAddress = customerDataDeliveryAddress;
      return customerData;
    },
    buildCustomerDataHouseConnectionOrder(houseConnectionOrderDto) {
      const customerData = {};
      const installationAddress = houseConnectionOrderDto.installationAddress;
      const invoiceAddress = houseConnectionOrderDto.invoiceAddress;
      const deliveryAddress = houseConnectionOrderDto.deliveryAddress;

      const ownerAddresss = houseConnectionOrderDto.ownerAddress;
      const customerInformation =
        this.buildCustomerInformation(installationAddress);
      const customerDataInstallationAddress =
        this.buildAddressLocationInformation(installationAddress);
      let deviatingOwnerInformationIsSelected = false;
      let deviatingOwnerInformation = null;
      let deviatingDeliveryAddressIsSelected = null;
      let customerDataDeliveryAddress = null;

      if (
        deliveryAddress &&
        lodash.isEqual(installationAddress, deliveryAddress) === false
      ) {
        deviatingDeliveryAddressIsSelected = true;
        customerDataDeliveryAddress =
          this.buildAddressInformation(deliveryAddress);
      } else {
        deviatingDeliveryAddressIsSelected = false;
        customerDataDeliveryAddress = null;
      }

      if (lodash.isEqual(installationAddress, ownerAddresss) === false) {
        deviatingOwnerInformationIsSelected = true;
        deviatingOwnerInformation = this.buildOwnerInformation(ownerAddresss);
        if (lodash.isEqual(invoiceAddress, ownerAddresss) === true) {
          deviatingOwnerInformation.ownerPaysForHouseConnection = true;
        } else {
          deviatingOwnerInformation.ownerPaysForHouseConnection = false;
        }
      } else {
        deviatingOwnerInformationIsSelected = false;
        deviatingOwnerInformation = null;
      }

      customerData.customerInformation = customerInformation;
      customerData.installationAddress = customerDataInstallationAddress;
      customerData.deviatingOwnerInformationIsSelected =
        deviatingOwnerInformationIsSelected;
      customerData.deviatingOwnerInformation = deviatingOwnerInformation;
      customerData.deviatingDeliveryAddressIsSelected =
        deviatingDeliveryAddressIsSelected;
      customerData.deliveryAddress = customerDataDeliveryAddress;
      return customerData;
    },
    async buildServiceOrderProducts(taskId, orderedProducts) {
      return HTTP.get(
        `/orderTask/finalizeOrder/productDetailsServiceOrder/${taskId}`
      )
        .then((resp) => {
          return resp.data;
        })
        .then((productsWithDetails) => {
          const restoredProducts = {
            oneTimeOrderItemProducts:
              productsWithDetails.oneTimeOrderItemProduct,
            internetMainProduct: productsWithDetails.internetProduct,
            internetOptionalProducts:
              productsWithDetails.internetOptionProducts,
            cpeDeviceProduct: productsWithDetails.cpeDeviceProduct,
            ontDeviceProduct: productsWithDetails.ontDeviceProduct,
            voiceMainProduct: productsWithDetails.voiceProduct,
            voiceOptionalProducts: productsWithDetails.voiceOptionProducts,
            tvMainProduct: productsWithDetails.tvProduct,
            tvOptionalProducts: productsWithDetails.tvOptionProducts,
            tvDeviceProduct: productsWithDetails.tvDeviceProduct
          };
          if (
            restoredProducts.tvDeviceProduct &&
            orderedProducts &&
            orderedProducts.tvDeviceProduct
          ) {
            restoredProducts.tvDeviceProduct.orderedQuantity =
              orderedProducts.tvDeviceProduct.quantity;
          }
          return restoredProducts;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Prozessdaten konnten nicht geladen werden!'
            )
          )
        );
    },
    async buildHouseConnectionOrderProducts(taskId) {
      return HTTP.get(
        `/orderTask/finalizeOrder/productDetailsHouseConnectionOrder/${taskId}`
      )
        .then((resp) => {
          return resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Prozessdaten konnten nicht geladen werden!'
            )
          )
        );
    },
    buildProductFromSingleProductId(optionalProductId) {
      return optionalProductId ? { id: optionalProductId } : null;
    },
    buildProductsFromOptionalProductIds(optionalProductIds) {
      if (optionalProductIds && optionalProductIds !== []) {
        return optionalProductIds.map((productId) => {
          return {
            id: productId
          };
        });
      } else {
        return [];
      }
    },
    buildServiceOrderOptions(serviceOrder) {
      const serviceOrderOptions = lodash.cloneDeep(
        defaultValuesServiceOrderOptions
      );
      if (serviceOrder.newPhoneNumberDetails) {
        serviceOrderOptions.newPhoneNumberDetails =
          serviceOrder.newPhoneNumberDetails;
      }
      if (serviceOrder.invoiceShippingMethod) {
        serviceOrderOptions.invoiceShippingMethod =
          serviceOrder.invoiceShippingMethod;
      }
      if (serviceOrder.itemizedBillsGenerationType) {
        serviceOrderOptions.itemizedBillsGenerationType =
          serviceOrder.itemizedBillsGenerationType;
      }
      if (serviceOrder.phoneBookOptions) {
        serviceOrderOptions.phoneBookOptions = serviceOrder.phoneBookOptions;
        if (serviceOrder.phoneBookOptions.phoneBookRecordType !== 'NO_ENTRY') {
          serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress =
            this.buildAddressInformation(
              serviceOrder.phoneBookOptions.phoneBookEntryAddress
            );
        }
      }
      if (serviceOrder.performPhoneNumberPorting) {
        serviceOrderOptions.performPhoneNumberPorting =
          serviceOrder.performPhoneNumberPorting;
      }
      if (serviceOrder.portingDetails) {
        serviceOrderOptions.performPhoneNumberPorting = true;
        serviceOrderOptions.portingDetails = serviceOrder.portingDetails;
      }
      if (serviceOrder.storingPeriodOfConnectionInformation) {
        serviceOrderOptions.storingPeriodOfConnectionInformation =
          serviceOrder.storingPeriodOfConnectionInformation;
      }
      if (serviceOrder.allowAdvertisement) {
        serviceOrderOptions.allowAdvertisement =
          serviceOrder.allowAdvertisement;
      }
      return serviceOrderOptions;
    },
    async buildFiles(uploadedDocuments) {
      const idMap = {};
      uploadedDocuments.forEach((document) => {
        idMap[document.documentId] = document.documentType;
      });
      return HTTP.post(
        '/documentArchive/details',
        uploadedDocuments.map((document) => document.documentId)
      )
        .then((resp) => {
          return resp.data;
        })
        .then((loadedFiles) => {
          loadedFiles.forEach(
            (file) => (file.documentType = idMap[file.documentId])
          );
          return loadedFiles;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Prozessdaten konnten nicht geladen werden!'
            )
          )
        );
    },
    buildCustomerInformation(installationAddress) {
      return {
        salutation: installationAddress.salutation,
        firstName: installationAddress.firstName,
        lastName: installationAddress.lastName,
        company: installationAddress.company,
        email: installationAddress.email,
        birthDate: installationAddress.birthDate,
        phone: installationAddress.phone,
        cellPhoneNumber: installationAddress.cellPhoneNumber
      };
    },
    buildAddressLocationInformation(installationAddress) {
      return {
        alternativeLocationDescriptionIsSelected:
          installationAddress.alternativeLocationDescription !== null &&
          installationAddress.alternativeLocationDescription !== undefined,
        zipCode: installationAddress.zipCode,
        city: installationAddress.city,
        street: installationAddress.street,
        houseNumber: installationAddress.houseNumber,
        houseNumberSupplement: installationAddress.houseNumberSupplement,
        alternativeLocationDescription:
          installationAddress.alternativeLocationDescription
      };
    },
    buildAddressInformation(addressDto) {
      return {
        customerInformation: this.buildCustomerInformation(addressDto),
        address: this.buildAddressLocationInformation(addressDto)
      };
    },
    buildOwnerInformation(ownerAddresss) {
      return {
        customerInformation: this.buildCustomerInformation(ownerAddresss),
        address: this.buildAddressLocationInformation(ownerAddresss),
        ownerPaysForHouseConnection: false
      };
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
