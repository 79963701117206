<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerData"
            :customerId="customerId"
            :data="customerData"
            :parseSalutation="true"
            title="Kundeninformationen"
          />
          <DisplayContainer label="Vertragsinformationen">
            <div class="input-row">
              <DataDisplay label="Vertragsnummer">
                <div v-if="
                    serviceContractId !== undefined && serviceContractId != null
                  ">
                  {{ serviceContractId }}
                </div>
              </DataDisplay>
              <DataDisplay label="Vertrag Unterschrieben">
                <div v-if="contractDates !== undefined && contractDates != null">
                  {{
                    new Date(contractDates.dateSigned).toLocaleDateString(
                      'de-De',
                      { year: 'numeric', month: '2-digit', day: '2-digit' }
                    )
                  }}
                </div>
              </DataDisplay>
              <DataDisplay label="Adresse">
                <div>
                  {{ contractAddress.street }} {{ contractAddress.houseNumber
                  }}{{ contractAddress.houseNumberSupplement }}
                </div>
                <div>
                  {{ contractAddress.zipCode }} {{ contractAddress.city }} <span v-if="contractAddress.district"> - {{ contractAddress.district }}</span>
                </div>
              </DataDisplay>
            </div>
          </DisplayContainer>
          <DisplayContainer label="Projektinformationen">
            <div class="input-row">
              <DataDisplay label="Projekt ID">
                <div v-if="projectId !== undefined && projectId != null">
                  {{ projectId }}
                </div>
                <div v-else>
                  -
                </div>
              </DataDisplay>
              <DataDisplay label="Projekt Name">
                <div v-if="projectName !== undefined && projectName != null">
                  {{ projectName }}
                </div>
                <div v-else>
                  -
                </div>
              </DataDisplay>
              <DataDisplay label="2G-Status">
                <div v-if="technologyState != undefined && technologyState != null">
                  <v-icon
                    class="technology-state-icon"
                    small
                  >
                    mdi-state-machine
                  </v-icon>{{ technologyState }}
                </div>
                <div v-else>
                  -
                </div>
              </DataDisplay>
            </div>
          </DisplayContainer>
          <DisplayContainer label="Gebuchte Produkte">
            <AllOrderedProductsTable :allProducts="contractProducts" />
          </DisplayContainer>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="buttons-wrapper"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              large
              v-bind="attrs"
              :disabled="buttonsDisabled"
              v-on="on"
              @click="completeTask('accepted')"
            >
              Technisch möglich
            </v-btn>
          </template>
          <span>Der Tarif ist technisch umsetzbar und der Auftrag wird normal
            prozessiert</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              large
              v-bind="attrs"
              :disabled="buttonsDisabled"
              v-on="on"
              @click="popupDowngrade = !popupDowngrade"
            >
              Downgrade anbieten
            </v-btn>
          </template>
          <span>Der Tarif ist technisch nicht möglich, Aufgabe erstellen zum Downgrade anbieten</span>
        </v-tooltip>
      </div>
      <v-dialog
        v-model="popupDowngrade"
        width="20vw"
      >
        <v-card>
          <v-card-title> Aufgabe weitergeben </v-card-title>
          <v-card-text>
            <p>
              Die Aufgabe weitergeben, damit dem Kunden ein Downgrade angeboten werden kann?
            </p>
            <v-spacer></v-spacer>
            <div class="dialog-buttons">
              <v-btn
                small
                color="secondary"
                class="align-center"
                @click="popupDowngrade = !popupDowngrade"
              >
                Abbrechen
              </v-btn>
              <v-btn
                small
                color="primary"
                class="align-center"
                @click="completeTask('downgrade')"
              >
                Ja
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import CustomerDetails from '@/components/CustomerDetails.vue';
import ErrorsDisplay from '@/components/elements/ErrorDisplay.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import NotificationObject from '@/main/NotificationObject.js';
import TaskBlocker from '../TaskBlocker.vue';
export default {
  name: 'ActionVerifyGoe2000Tariff',
  components: {
    ErrorsDisplay,
    CustomerDetails,
    AllOrderedProductsTable,
    DisplayContainer,
    TaskBlocker,
    DataDisplay: () => import('@/components/elements/DataDisplay.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      popupDowngrade: false,
      serviceContractId: undefined,
      projectId: undefined,
      customerId: undefined,
      customerData: undefined,
      contractAddress: undefined,
      technologyState: 'Kein 2g Status gesetzt',
      contractProducts: undefined,
      contractDates: {},
      isLoading: false,
      projectName: 'Keine Projekt Zuordnung'
    };
  },
  computed: {
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  created: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.serviceContractId = resp.data.serviceContractId.value;
          this.projectId = resp.data.projectId.value;
          this.customerId = resp.data.serviceCustomerId.value;
          return Promise.allSettled([
            this.fetchCustomerInfo(this.serviceContractId),
            this.loadContractDates(this.serviceContractId),
            this.loadContractProducts(this.serviceContractId),
            this.fetchContractInfo(this.serviceContractId),
            this.fetchProjectInfo(this.projectId)
          ]);
        })
        .catch((err) => {
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.message,
              'Ein Fehler ist aufgetreten'
            )
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchCustomerInfo(contractId) {
      return HTTP.get(`/customer/addressByType/${contractId}/INVOICE`).then(
        (resp) => {
          this.customerData = resp.data;
          this.customerData.mobil = this.customerData.cellPhoneNumber;
        }
      );
    },
    fetchContractInfo(contractId) {
      return HTTP.get(
        `/customer/addressByType/${contractId}/INSTALLATION`
      ).then((resp) => {
        this.contractAddress = resp.data;
      });
    },
    fetchProjectInfo(projectId) {
      return HTTP.get(
        `/past/projects?where[projektId][equals]=${projectId}`
      ).then((resp) => {
        if (resp.data && resp.data.length > 0) {
          this.projectName = `${resp.data[0].city}, ${resp.data[0].district}`;
        }
        if (
          resp.data[0].technologyStatus &&
          resp.data[0].technologyStatus.length > 0
        ) {
          this.retrieveTechnologyStateDescription(
            resp.data[0].technologyStatus
          );
        }
      });
    },
    retrieveTechnologyStateDescription(technologyStatus) {
      for (const element of technologyStatus) {
        if (
          element.technology &&
          element.technology.name &&
          element.technology.name === 'ftth2g'
        ) {
          if (element.status && element.status.description) {
            this.technologyState = element.status.description;
            break;
          }
        }
      }
    },
    loadContractProducts: function (contractId) {
      return HTTP.get(`/products/contract/all/${contractId}`).then((resp) => {
        this.contractProducts = resp.data;
      });
    },
    loadContractDates: function (contractId) {
      HTTP.get(`/contracts/dates/${contractId}`).then((resp) => {
        this.contractDates = resp.data;
      });
    },
    completeTask: function (validationResult) {
      HTTP.post(
        `orderTask/validateGoe2000Tarif/completeTask/${this.data.id}?validationResult=${validationResult}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(() => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    }
  }
};
</script>
<style scoped>
.dialog-buttons {
  text-align: right;
}
.dialog-buttons :first-child {
  margin-right: 5px;
}
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
.technology-state-icon {
  margin-right: 5px;
  bottom: 2px;
}
</style>
