<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>

        <div v-if="!isLoading">
          <div>
            <div class="prozess-step">
              Prüfergebnis
            </div>
            <div class="group-description">
              <span>Neuer Auftrag</span>
              <div class="input-row">
                <span class="toggle-description">Akzeptiert:</span>
                <toggle-button
                  v-model="acceptNewHouseConnectionContract"
                  :labels="{ checked: 'Ja', unchecked: 'Nein' }"
                  :color="{ checked: '#0CF0B8', unchecked: '#777777' }"
                  @change="decisionCanBeStored"
                />
              </div>
            </div>
            <div v-if="acceptNewHouseConnectionContract == true">
              <IncomingHouseConnectionOrderCustomerData
                ref="incomingHouseConnectionOrderCustomerData"
                @updatedOrder="acceptOrder($event)"
              />
            </div>
          </div>

          <div>
            <h3 class="prozess-step">
              Auftragsdaten
            </h3>
            <CustomerDetails
              v-if="installationAddress !== null"
              :data="installationAddress"
              parseSalutation
              title="Anschlussaddresse"
            />

            <CustomerDetails
              v-if="ownerAddress !== null"
              :data="ownerAddress"
              parseSalutation
              title="Adresse Hausbesitzer"
            />

            <CustomerDetails
              v-if="invoiceAddress !== null"
              :data="invoiceAddress"
              parseSalutation
              title="Rechnungsaddress Kunde"
            />
          </div>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <NormalButton
          :disabled="decisionCanBeStoredFlag || !claimed"
          description="Prüfergebnis speichern"
          @onClick="storeDecision()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import IncomingHouseConnectionOrderCustomerData from './IncomingHouseConnectionOrderCustomerData.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ActionNewHouseConnectionOrderValidation',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerDetails,
    Loader,
    IncomingHouseConnectionOrderCustomerData
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    acceptNewHouseConnectionContract: false,
    decisionCanBeStoredFlag: false,
    installationAddress: null,
    invoiceAddress: null,
    ownerAddress: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(
        `/orderTask/newHouseConnectionOrderValidation/process-data/${id}`
      )
        .then((resp) => {
          this.installationAddress = this.buildAddress(
            resp.data.installationAddress
          );
          this.invoiceAddress = this.buildAddress(resp.data.invoiceAddress);
          this.ownerAddress = this.buildAddress(resp.data.ownerAddress);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Die Prozessdaten konnten nicht geladen werden!')
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    decisionCanBeStored: function (inputformOfNewOrderValid) {
      if (
        this.acceptNewHouseConnectionContract === true &&
        inputformOfNewOrderValid === true
      ) {
        this.decisionCanBeStoredFlag = true;
      } else {
        this.decisionCanBeStoredFlag = false;
      }
    },
    storeDecision: function () {
      if (this.acceptNewHouseConnectionContract === true) {
        this.$refs.incomingHouseConnectionOrderCustomerData.buildCustomerData();
      } else {
        this.declineOrder();
      }
    },
    acceptOrder: function (newHouseConnectionOrder) {
      HTTP.post(
        `/orderTask/newHouseConnectionOrderValidation/accept-order/${this.data.id}`,
        newHouseConnectionOrder
      )
        .then((resp) => {
          this.showMessage(
            'info',
            'Die Entscheidung wurde erfolgreich übertragen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Die Entscheidung konnte nicht erfolgreich übertragen werden.')
          )
        );
    },
    declineOrder: function () {
      HTTP.post(
        `/orderTask/newHouseConnectionOrderValidation/decline-order/${this.data.id}`
      )
        .then((resp) => {
          this.showMessage(
            'info',
            'Die Entscheidung wurde erfolgreich übertragen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(err.response.data, 'Die Entscheidung konnte nicht erfolgreich übertragen werden.')
          )
        );
    },
    buildAddress: function (addressDto) {
      return {
        salutation: addressDto.salutation,
        firstName: addressDto.firstName,
        lastName: addressDto.lastName,
        phone: addressDto.phone,
        email: addressDto.email,
        street: addressDto.street,
        houseno: addressDto.houseNumber,
        housenoSuppl: addressDto.houseNumberSupplement,
        cityCode: addressDto.zipCode,
        city: addressDto.city,
        company: addressDto.company
      };
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.loader {
  margin: auto;
}
</style>
