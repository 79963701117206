<template>
  <div class="prozess-wrapper">
    <div>
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>
      <div v-if="!isLoading">
        <!-- Falls Seloca-Fehler: Anzeigen Seloca-Fehler -->
        <ErrorsDisplay
          v-if="orderErrors || errorMessageSeloca"
          class="margin-top-2"
        >
          <div v-if="errorMessageSeloca">
            {{ errorMessageSeloca }}
          </div>
          <ul v-for="(error, index) in orderErrors" :key="`error-${index}`">
            <li>{{ error }}</li>
          </ul>
        </ErrorsDisplay>
        <DisplayContainer label="Kundeninformationen">
          <div class="input-row">
            <DataDisplay label="Kundennummer">
              <div v-if="customerId">
                {{ customerId }}
              </div>
            </DataDisplay>
            <DataDisplay label="Vertragsnummer ">
              <div v-if="contractId">
                {{ contractId }}
              </div>
            </DataDisplay>
          </div>
          <DisplayContainer
            :label="'Installationadresse'"
            :isSubcomponent="true"
          >
            <ContactInformation :addressDto="installationAddress" />
          </DisplayContainer>
        </DisplayContainer>
        <DisplayContainer label="Lieferadresse">
          <AddressLabelEditor
            v-if="shippingAddress"
            :address="shippingAddress"
            :maxLength="shippingAddressMaxLength"
            :disabled="!claimed"
            @onChange="onChange"
          />
        </DisplayContainer>
        <DisplayContainer label="Gebuchte Produkte">
          <AllOrderedProductsTable :allProducts="contractProducts" />
        </DisplayContainer>
        <DisplayContainer label="Versandoptionen">
          <v-tabs
            v-model="selectedTabIndex"
            :background-color="'var(--goe-background-highlight)'"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in tabOptions"
              :key="item.goetelShipsDevices"
              :disabled="!claimed || item.disabled"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="selectedTabIndex"
            :background-color="'var(--goe-background-highlight)'"
          >
            <v-tab-item eager>
              <v-card flat :color="'var(--goe-background-highlight)'">
                <DisplayContainer
                  v-if="shipRouter"
                  label="Routeroptionen"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      productType="CPE"
                      :isRequired="shipRouter"
                      :disabled="routerProductSelectionDisabled || !claimed"
                      :presentProductData="availableRouterProduct"
                      :previouslySelectedProduct="selectedRouterProduct"
                      @selectedProductChanged="selectedRouterProduct = $event"
                    />
                  </div>
                  <ModelSelection
                    :disabled="false"
                    :isRequired="shipRouter"
                    :previouslySelectedModel="selectedRouterModel"
                    :deviceType="
                      selectedRouterProduct
                        ? selectedRouterProduct.requiredDevice
                        : null
                    "
                    @selectedModelChanged="selectedRouterModel = $event"
                  />
                </DisplayContainer>
                <DisplayContainer
                  v-if="shipONT"
                  label="ONT-Optionen"
                  class="margin-top-1"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      productType="ONT"
                      :isRequired="shipONT"
                      :disabled="ontProductSelectionDisabled || !claimed"
                      :presentProductData="availableOntProduct"
                      :previouslySelectedProduct="selectedOntProduct"
                      @selectedProductChanged="selectedOntProduct = $event"
                    />
                  </div>
                  <ModelSelection
                    :disabled="ontDeviceSelectionDisabled || !claimed"
                    :isRequired="shipONT"
                    :previouslySelectedModel="selectedOntModel"
                    :deviceType="
                      selectedOntProduct
                        ? selectedOntProduct.requiredDevice
                        : null
                    "
                    @selectedModelChanged="selectedOntModel = $event"
                  />
                </DisplayContainer>
                <DisplayContainer
                  v-if="shipSTB"
                  label="TV-Optionen"
                  class="margin-top-1"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      productType="STB"
                      :isRequired="shipSTB"
                      :disabled="tvProductSelectionDisabled || !claimed"
                      :presentProductData="availableTvProduct"
                      :previouslySelectedProduct="selectedTvProduct"
                      @selectedProductChanged="selectedTvProduct = $event"
                    />
                  </div>
                  <ModelSelection
                    :disabled="tvDeviceSelectionDisabled || !claimed"
                    :isRequired="shipSTB"
                    :previouslySelectedModel="selectedTvModel"
                    :deviceType="
                      selectedTvProduct
                        ? selectedTvProduct.requiredDevice
                        : null
                    "
                    @selectedModelChanged="selectedTvModel = $event"
                  />
                </DisplayContainer>
              </v-card>
            </v-tab-item>
            <v-tab-item eager>
              <v-card flat :color="'var(--goe-background-highlight)'">
                <DisplayContainer
                  v-if="shipRouter"
                  label="Routeroptionen"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      ref="ontProductSelection"
                      productType="CPE"
                      :isRequired="shipRouter"
                      :disabled="routerProductSelectionDisabled || !claimed"
                      :presentProductData="availableRouterProduct"
                      :previouslySelectedProduct="selectedRouterProduct"
                      @selectedProductChanged="selectedRouterProduct = $event"
                    />
                    <HardwareDeviceSelection
                      :disabled="routerDeviceSelectionDisabled || !claimed"
                      :isRequired="shipRouter"
                      :presentDeviceData="availableRouterDevice"
                      :deviceType="
                        selectedRouterProduct
                          ? selectedRouterProduct.requiredDevice
                          : null
                      "
                      @selectedDeviceChanged="selectedRouterDevice = $event"
                    />
                  </div>
                </DisplayContainer>
                <DisplayContainer
                  v-if="shipONT"
                  label="ONT-Optionen"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      productType="ONT"
                      :isRequired="shipONT"
                      :disabled="ontProductSelectionDisabled || !claimed"
                      :presentProductData="availableOntProduct"
                      :previouslySelectedProduct="selectedOntProduct"
                      @selectedProductChanged="selectedOntProduct = $event"
                    />
                    <HardwareDeviceSelection
                      ref="ontDeviceSelection"
                      :disabled="ontDeviceSelectionDisabled || !claimed"
                      :isRequired="shipONT"
                      :presentDeviceData="availableOntDevice"
                      :deviceType="
                        selectedOntProduct
                          ? selectedOntProduct.requiredDevice
                          : null
                      "
                      @selectedDeviceChanged="selectedOntDevice = $event"
                    />
                  </div>
                </DisplayContainer>
                <DisplayContainer
                  v-if="shipSTB"
                  label="TV-Optionen"
                  :isSubcomponent="true"
                >
                  <div class="input-row margin-top-1">
                    <ProductSelection
                      ref="ontProductSelection"
                      productType="STB"
                      :isRequired="shipSTB"
                      :disabled="tvProductSelectionDisabled || !claimed"
                      :presentProductData="availableTvProduct"
                      :previouslySelectedProduct="selectedTvProduct"
                      @selectedProductChanged="selectedTvProduct = $event"
                    />
                    <HardwareDeviceSelection
                      ref="ontDeviceSelection"
                      :disabled="tvDeviceSelectionDisabled || !claimed"
                      :isRequired="shipSTB"
                      :presentDeviceData="availableTvDevice"
                      :deviceType="
                        selectedTvProduct
                          ? selectedTvProduct.requiredDevice
                          : null
                      "
                      @selectedDeviceChanged="selectedTvDevice = $event"
                    />
                  </div>
                </DisplayContainer>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </DisplayContainer>
        <div class="margin-bottom-2 align-buttons-right">
          <v-btn
            :disabled="hasError || !claimed || !taskCanBeFinished"
            large
            color="primary"
            @click="
              if (goetelShipsDevices === true) {
                completeTaskByGoetel();
              } else {
                completeTaskBySeloca();
              }
            "
          >
            {{ finishTaskButtonLabel }}
          </v-btn>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import ErrorsDisplay from '@/components/elements/ErrorDisplay.vue';
import Loader from '@/components/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import ProductSelection from '@/components/deviceProvisioning/ProductSelection.vue';
import HardwareDeviceSelection from '@/components/deviceProvisioning/HardwareDeviceSelection.vue';
import ModelSelection from '../orderEntry/ModelSelection.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import ContactInformation from '@/components/ContactInformation.vue';
import AddressLabelEditor from '@/components/AddressLabelEditor.vue';

export default {
  components: {
    ModelSelection,
    ErrorsDisplay,
    Loader,
    TaskBlocker,
    AllOrderedProductsTable,
    DisplayContainer,
    ProductSelection,
    HardwareDeviceSelection,
    ContactInformation,
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    AddressLabelEditor
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      contractId: null,
      customerId: null,
      shipONT: false,
      shipRouter: false,
      shipSTB: false,
      orderErrors: null,
      contractProducts: null,
      errorMessageSeloca: null,
      shippingAddress: null,
      shippingAddressMaxLength: null,
      availableRouterProduct: null,
      availableRouterDevice: null,
      availableOntProduct: undefined,
      availableOntDevice: null,
      availableTvProduct: null,
      availableTvDevice: null,
      selectedRouterProduct: undefined,
      selectedRouterDevice: undefined,
      selectedRouterModel: undefined,
      selectedOntProduct: undefined,
      selectedOntModel: undefined,
      selectedOntDevice: undefined,
      selectedTvProduct: undefined,
      selectedTvModel: undefined,
      selectedTvDevice: undefined,
      tabOptions: [
        { goetelShipsDevices: false, title: 'Seloca', disabled: false },
        { goetelShipsDevices: true, title: 'Manuell', disabled: false }
      ],
      selectedTabIndex: 0,
      hasError: undefined
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading;
    },
    routerProductSelectionDisabled() {
      return (
        this.availableRouterProduct !== null &&
        this.availableRouterProduct !== undefined
      );
    },
    routerDeviceSelectionDisabled() {
      return (
        this.availableRouterDevice !== null &&
        this.availableRouterDevice !== undefined
      );
    },
    ontProductSelectionDisabled() {
      return (
        this.availableOntProduct !== null &&
        this.availableOntProduct !== undefined
      );
    },
    ontDeviceSelectionDisabled() {
      return (
        this.availableOntDevice !== null &&
        this.availableOntDevice !== undefined
      );
    },
    tvProductSelectionDisabled() {
      return (
        this.availableTvProduct !== null &&
        this.availableTvProduct !== undefined
      );
    },
    tvDeviceSelectionDisabled() {
      return (
        this.availableTvDevice !== null && this.availableTvDevice !== undefined
      );
    },
    taskCanBeFinished() {
      if (this.shipRouter && !this.selectedRouterProduct) {
        return false;
      }
      if (this.shipONT && !this.selectedOntProduct) {
        return false;
      }
      if (this.shipSTB && !this.selectedTvProduct) {
        return false;
      }

      if (this.goetelShipsDevices) {
        if (this.shipRouter && !this.selectedRouterDevice) {
          return false;
        }
        if (this.shipONT && !this.selectedOntDevice) {
          return false;
        }
        if (this.shipSTB && !this.selectedTvDevice) {
          return false;
        }
      } else {
        if (this.shipRouter && !this.selectedRouterModel) {
          return false;
        }
        if (this.shipONT && !this.selectedOntModel) {
          return false;
        }
        if (this.shipSTB && !this.selectedTvModel) {
          return false;
        }
      }
      return true;
    },
    goetelShipsDevices() {
      return this.tabOptions[this.selectedTabIndex].goetelShipsDevices;
    },
    finishTaskButtonLabel() {
      return this.goetelShipsDevices
        ? 'Manuellen Versand abschließen'
        : 'Erneuter Seloca-Versand';
    }
  },
  mounted: function () {
    this.loadData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadData: function (id) {
      this.isLoading = true;
      this.loadTaskData(id)
        .then((response) => {
          const contractId = response.data.contractId.value;
          const backendCalls = [
            this.loadContractProducts(contractId).then((resp) => {
              this.contractProducts = resp.data;
            }),
            this.loadCpeContractProducts(contractId, 'ROUTER').then((resp) => {
              this.availableRouterProduct = resp.data?.product;
              this.availableRouterDevice = resp.data?.device;
              return resp;
            }),
            this.loadCpeContractProducts(contractId, 'ONT').then((resp) => {
              this.availableOntProduct = resp.data?.product;
              this.availableOntDevice = resp.data?.device;
              return resp;
            }),
            this.loadCpeContractProducts(contractId, 'STB').then((resp) => {
              this.availableTvProduct = resp.data?.product;
              this.availableTvDevice = resp.data?.device;
              return resp;
            })
          ];
          const selocaInformation = response.data.selocaOrderInformation
            ? response.data.selocaOrderInformation.value
            : null;
          if (selocaInformation?.status === 'FEEDBACK_FAULTY') {
            backendCalls.push(
              this.loadFeedbackFileInformation(
                selocaInformation.feedbackFilename
              ).then((resp) => {
                if (resp.data?.routerServiceDataId) {
                  this.availableRouterDevice = {
                    serviceDataId: resp.data?.routerServiceDataId,
                    serialNumber: resp.data?.routerSerialNumber,
                    model: resp.data?.routerModel
                  };
                }
                if (resp.data?.ontServiceDataId) {
                  this.availableOntDevice = {
                    serviceDataId: resp.data?.ontServiceDataId,
                    serialNumber: resp.data?.ontSerialNumber,
                    model: resp.data?.ontModel
                  };
                }
                return resp;
              })
            );
          }
          return Promise.allSettled(backendCalls);
        })
        .catch((e) => {
          console.log(e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadTaskData: function (id) {
      return HTTP.get(`/task/${id}/variables`).then((resp) => {
        this.contractId = resp.data.contractId.value;
        this.customerId = resp.data.customerId.value;
        this.shipONT = resp.data.shipONT.value;
        this.shipRouter = resp.data.shipRouter.value;
        this.shipSTB = resp.data.shipSTB.value;
        this.shippingAddress = resp.data.shippingAddress.value;
        this.shippingAddressMaxLength =
          resp.data.shippingAddressMaxLength.value;
        this.selocaInformation = resp.data.selocaOrderInformation
          ? resp.data.selocaOrderInformation.value
          : null;
        if (this.selocaInformation) {
          if (this.selocaInformation.status === 'ORDER_FAULTY') {
            this.errorMessageSeloca = 'Fehler in der Auftragsdatei';
          } else if (this.selocaInformation.status === 'FEEDBACK_FAULTY') {
            this.errorMessageSeloca = 'Fehler in der Rückmeldedatei';
            this.tabOptions[0].disabled = true;
            this.selectedTabIndex = 1;
          } else if (
            this.selocaInformation.status === 'GOODS_RECEIPT_FILE_RECEIVED'
          ) {
            this.errorMessageSeloca = 'Retouren-Datei eingegangen';
          } else {
            this.errorMessageSeloca =
              'Fehler bei der automatischen Artikelauswahl für den Versand';
          }
        }
        if (this.selocaInformation?.orderErrors) {
          this.orderErrors = ErrorMessageBuilder.buildErrorMessages(
            this.selocaInformation.orderErrors
          );
        }
        return resp;
      });
    },
    loadContractProducts: function (contractId) {
      return HTTP.get(`/products/contract/all/${contractId}`);
    },
    loadShippingAddress: function (contractId) {
      return HTTP.get(`/customer/addressByType/${contractId}/DELIVERY`);
    },
    loadCpeContractProducts: function (contractId, cpeDeviceCategory) {
      return HTTP.get(`/products/contract/cpe/${contractId}`, {
        params: {
          cpeDeviceCategory: cpeDeviceCategory
        }
      });
    },
    loadFeedbackFileInformation: function (feedbackFileName) {
      return HTTP.get(
        `/selocaTask/feedbackFileInformation/${feedbackFileName}`
      );
    },
    completeTaskByGoetel: function () {
      const requestParameters = this.buildPostBodyFinishTaskByGoetel();
      HTTP.post(
        `/selocaTask/sendDevicesToCustomer/finishByGoetel/${this.data.id}`,
        requestParameters,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Der Abschluss der Aufgabe war nicht erfolgreich!'
          )
        );
    },
    buildPostBodyFinishTaskByGoetel() {
      const requestParameters = {};
      if (!this.availableRouterProduct && this.selectedRouterProduct) {
        requestParameters.routerProductId = this.selectedRouterProduct.id;
      }
      if (!this.availableOntProduct && this.selectedOntProduct) {
        requestParameters.ontProductId = this.selectedOntProduct.id;
      }
      if (!this.availableTvProduct && this.selectedTvProduct) {
        requestParameters.tvProductId = this.selectedTvProduct.id;
      }
      if (this.selectedRouterDevice) {
        requestParameters.routerDeviceId =
          this.selectedRouterDevice.serviceDataId;
      }
      if (this.selectedOntDevice) {
        requestParameters.ontDeviceId = this.selectedOntDevice.serviceDataId;
      }
      if (this.selectedTvDevice) {
        requestParameters.tvDeviceId = this.selectedTvDevice.serviceDataId;
      }
      requestParameters.shippingAddress = this.shippingAddress;
      return requestParameters;
    },
    completeTaskBySeloca: function () {
      const orderedSelocaArticleIds = this.buildOrderedSelocaArticleIds();
      HTTP.post(
        `/selocaTask/sendDevicesToCustomer/finishBySeloca/${this.data.id}`,
        orderedSelocaArticleIds,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Der Abschluss der Aufgabe war nicht erfolgreich!'
          )
        );
    },
    buildOrderedSelocaArticleIds() {
      const requestParameters = {};
      if (!this.availableRouterProduct && this.selectedRouterProduct) {
        requestParameters.routerProductId = this.selectedRouterProduct.id;
      }
      if (!this.availableOntProduct && this.selectedOntProduct) {
        requestParameters.ontProductId = this.selectedOntProduct.id;
      }
      if (!this.availableTvProduct && this.selectedTvProduct) {
        requestParameters.tvProductId = this.selectedTvProduct.id;
      }
      if (this.selectedRouterModel) {
        requestParameters.routerArticleId = this.selectedRouterModel.id;
      }
      if (this.selectedOntModel) {
        requestParameters.ontArticleId = this.selectedOntModel.id;
      }
      if (this.selectedTvDevice) {
        requestParameters.tvArticleId = this.selectedTvModel.id;
      }
      requestParameters.shippingAddress = this.shippingAddress;
      return requestParameters;
    },
    buildValidationErrorMessage(validationErrorDto) {
      const code = validationErrorDto.code;
      const payload = validationErrorDto.payload;
      if (code === 'SELOCA_ARTICLE_NOT_FOUND') {
        const productType = this.buildProductTypeLabel(payload.productType);
        return `Für den automatischen Selocaversand wurde kein ${productType} gefunden oder es ist kein passender Artikel auf Lager.`;
      } else if (code === 'SELOCA_FEEDBACK_MISSING_ARTICLE') {
        const productType = this.buildProductTypeLabel(payload.productType);
        return `Es wurde kein ${productType} in der Feedbackdatei von Seloca gefunden.`;
      } else if (code === 'SELOCA_FEEDBACK_MISSING_SERIAL_NUMBER') {
        const productType = this.buildProductTypeLabel(payload.productType);
        return `Es wurde für den ${productType} keine Seriennummer in der Feedbackdatei von Seloca gefunden.`;
      } else if (code === 'SELOCA_FEEDBACK_MISSING_ROUTER_CWMP') {
        return 'Für den Router wurde keine CWMP in der Feedbackdatei von Seloca gefunden.';
      } else if (code === 'SELOCA_FEEDBACK_SERIAL_NUMBER_MISSING_IN_DIMARI') {
        const productType = this.buildProductTypeLabel(payload.productType);
        const serialNumber = payload.serialNumber;
        return `Die in der Feedbackdatei hinterlegte Seriennummer ${serialNumber} für den ${productType} wurde nicht in der Feedbackdatei für Seloca gefunden und kann daher nicht automatisch im Vertrag hinterlegt werden.`;
      } else {
        return null;
      }
    },
    buildProductTypeLabel(productType) {
      if (productType === 'CPE_DEVICE') {
        return 'Router';
      } else if (productType === 'ONT_DEVICE') {
        return 'ONT';
      } else {
        return 'Zubehör';
      }
    },
    onChange(hasError, address) {
      this.hasError = hasError;
      this.shippingAddress = address;
    }
  }
};
</script>

<style scoped lang="scss">
.v-checkbox ::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
