<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>

        <div v-if="!isLoading">
          <DisplayContainer v-if="installationAddress" label="Auftragsdaten">
            <div class="input-row">
              <DataDisplay label="Auftragstyp">
                <div v-if="orderType">
                  {{ mapOrderTypeToLabel(orderType) }}
                </div>
              </DataDisplay>
              <div></div>

              <DataDisplay v-if="order" label="Kundennummer">
                <div v-if="serviceContractIdLabel">
                  {{ serviceCustomerIdLabel }}
                </div>
                <div v-if="houseConnectionCustomerIdLabel">
                  {{ houseConnectionCustomerIdLabel }}
                </div>
              </DataDisplay>

              <DataDisplay v-if="order" label="Vertragsnummer ">
                <div v-if="serviceContractIdLabel">
                  {{ serviceContractIdLabel }}
                </div>
                <div v-if="houseConnectionContractIdLabel">
                  {{ houseConnectionContractIdLabel }}
                </div>
              </DataDisplay>
            </div>
          </DisplayContainer>
          <DisplayContainer label="Liegenschaftsdaten">
            <DisplayContainer label="Adresse" :isSubcomponent="true">
              <div class="input-row">
                <NameDisplay :addressDto="installationAddress" />
                <AddressDisplay
                  :addressDto="installationAddress"
                  label="Installationsadresse"
                />
              </div>
            </DisplayContainer>
            <DisplayContainer label="Aktueller Status" :isSubcomponent="true">
              <div class="input-row">
                <DataDisplay label="Liegenschaftsstatus">
                  <div v-if="propertyStatus">
                    {{ propertyStatus }}
                  </div>
                </DataDisplay>
              </div>
            </DisplayContainer>
            <DisplayContainer
              label="Umliegende Ausbaugebiete"
              :isSubcomponent="true"
            >
              <ProjectsNearbyMap
                class="margin-top-0_5"
                :address="installationAddress"
              />
            </DisplayContainer>
          </DisplayContainer>
          <DisplayContainer label="Notizen">
            <v-textarea
              v-model="memo"
              :disabled="!claimed"
              :rules="[
                (v) =>
                  inputIsValid(v) ||
                  'Die Notiz darf maximal 10000 Zeichen lang sein!'
              ]"
              :value="memo"
              auto-grow
              class="input-field"
              clear-icon="mdi-delete"
              clearable
              counter="10000"
              name="memo-input"
            />
            <div class="margin-top-0_5 align-button-right">
              <v-btn
                :disabled="!claimed"
                color="secondary"
                large
                @click="saveTask(data.id, memo)"
              >
                Speichern
              </v-btn>
            </div>
          </DisplayContainer>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="margin-bottom-0_4 align-buttons-right">
        <v-btn
          :disabled="!claimed"
          large
          color="secondary"
          @click="completeCustomerInExpansionAreaTask(false)"
        >
          Auftrag ablehnen
        </v-btn>
        <v-btn
          :disabled="!claimed || !customerInExpansionArea"
          large
          color="primary"
          @click="completeCustomerInExpansionAreaTask(true)"
        >
          Liegenschaft aktzeptiert
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { camundaStoreLocalMemoVariable } from '@/main/PostCalls.js';
import NotificationObject from '@/main/NotificationObject.js';
import { HTTP } from '@/main/httpClient.js';
import OrderTypeUtil from '@/util/OrderTypeUtil.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  components: {
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    Loader: () => import('@/components/Loader.vue'),
    NormalButton: () => import('@/components/elements/NormalButton.vue'),
    TaskBlocker: () => import('../TaskBlocker.vue'),
    NameDisplay: () => import('@/components/dataDisplays/NameDisplay.vue'),
    AddressDisplay: () =>
      import('@/components/dataDisplays/AddressDisplay.vue'),
    ProjectsNearbyMap: () => import('./ProjectsNearbyMap.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      order: null,
      orderType: null,
      installationAddress: null,
      serviceContractId: null,
      serviceCustomerId: null,
      houseConnectionContractId: null,
      houseConnectionCustomerId: null,
      propertyStatus: null,
      projectAreas: null,
      memo: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    serviceCustomerIdLabel() {
      if (
        (this.orderType === 'SERVICE' && this.serviceCustomerId) ||
        (this.orderType === 'HOUSECONNECTION_WITH_SERVICE' &&
          this.serviceCustomerId &&
          this.serviceCustomerId === this.houseConnectionCustomerId)
      ) {
        return this.serviceCustomerId;
      } else if (
        this.orderType === 'HOUSECONNECTION_WITH_SERVICE' &&
        this.serviceCustomerId &&
        this.serviceCustomerId !== this.houseConnectionCustomerId
      ) {
        return `${this.serviceCustomerId} (Diensteauftrag)`;
      } else {
        return null;
      }
    },
    serviceContractIdLabel() {
      if (this.orderType === 'SERVICE' && this.serviceContractId) {
        return this.serviceContractId;
      } else if (
        this.orderType === 'HOUSECONNECTION_WITH_SERVICE' &&
        this.serviceContractId
      ) {
        return `${this.serviceContractId} (Diensteauftrag)`;
      } else {
        return null;
      }
    },
    houseConnectionCustomerIdLabel() {
      if (this.orderType === 'HOUSECONNECTION' && this.serviceCustomerId) {
        return this.houseConnectionCustomerId;
      } else if (
        this.orderType === 'HOUSECONNECTION_WITH_SERVICE' &&
        this.houseConnectionCustomerId &&
        this.serviceCustomerId !== this.houseConnectionCustomerId
      ) {
        return `${this.houseConnectionCustomerId} (Hausanschlussauftrag)`;
      } else {
        return null;
      }
    },
    houseConnectionContractIdLabel() {
      if (
        this.orderType === 'HOUSECONNECTION' &&
        this.houseConnectionContractId
      ) {
        return this.houseConnectionContractId;
      } else if (
        this.orderType === 'HOUSECONNECTION_WITH_SERVICE' &&
        this.houseConnectionContractId
      ) {
        return `${this.houseConnectionContractId} (Hausanschlussauftrag)`;
      } else {
        return null;
      }
    },
    customerInExpansionArea() {
      return this.propertyStatus && this.propertyStatus !== 'none';
    }
    // buttonsDisabled() {
    //   return this.isLoading || !this.inputIsValid(this.memo);
    // }
  },
  mounted: function () {
    this.loadData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadData: function (taskId) {
      this.isLoading = true;
      this.loadTaskData(taskId)
        .then((response) => {
          const zipCode = this.installationAddress.zipCode;
          const city = this.installationAddress.city;
          const street = this.installationAddress.street;
          const houseNumber = this.installationAddress.houseNumber;
          const houseNumberSupplement =
            this.installationAddress.houseNumberSupplement;
          return Promise.all([
            this.addressIsInProjectArea(zipCode, city, street, houseNumber),
            this.loadPropertyStatus(
              zipCode,
              city,
              street,
              houseNumber,
              houseNumberSupplement
            )
          ]);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadTaskData: function (id) {
      return HTTP.get(`/task/${id}/variables`).then((response) => {
        this.order = response.data.order.value;
        // this.propertyStatus = response.data.propertyStatus.value;
        this.orderType = this.order.orderType;

        if (this.order.orderType !== 'HOUSECONNECTION') {
          this.serviceContractId = response.data.serviceContractId.value;
          this.serviceCustomerId = response.data.serviceCustomerId.value;
        }
        if (this.order.orderType !== 'SERVICE') {
          this.houseConnectionContractId =
            response.data.houseConnectionContractId.value;
          this.houseConnectionCustomerId =
            response.data.houseConnectionCustomerId.value;
        }

        if (this.order.orderType === 'HOUSECONNECTION') {
          this.installationAddress =
            this.order.houseConnectionOrder.installationAddress;
        } else if (this.order.orderType === 'SERVICE') {
          this.installationAddress =
            this.order.serviceOrder.installationAddress;
        } else if (this.order.orderType === 'HOUSECONNECTION_WITH_SERVICE') {
          this.installationAddress =
            this.order.serviceOrder.installationAddress;
        }
        if (response.data.memo !== undefined) {
          this.memo = response.data.memo.value;
        }
        return response;
      });
    },
    loadPropertyStatus: function (
      zipCode,
      city,
      street,
      houseNumber,
      houseNumberSupplement
    ) {
      return HTTP.get('/productAvailabilityTool/propertyStatus', {
        params: {
          zipCode: zipCode,
          city: city,
          street: street,
          houseNumber: houseNumber,
          houseNumberSupplement: houseNumberSupplement
        }
      }).then((response) => {
        this.propertyStatus = response.data.status;
      });
    },
    addressIsInProjectArea: function (zipCode, city, street, houseNumber) {
      return HTTP.get('/productAvailabilityTool/projectAreas', {
        params: {
          zipCode: zipCode,
          city: city,
          street: street,
          houseNumber: houseNumber
        }
      }).then((response) => {
        this.projectAreas = response.data;
      });
    },
    saveTask: function (taskId, memo) {
      camundaStoreLocalMemoVariable(taskId, memo).then((res) => {
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'success',
            'Notiz wurde erfolgreich in der Camunda gespeichert.'
          )
        );
      });
    },
    completeCustomerInExpansionAreaTask: function (contractAccepted) {
      HTTP.post(
        `/orderTask/customerInExpansionArea/${this.data.id}`,
        {},
        {
          params: { contractAccepted: contractAccepted }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Abschluss der Aufgabe war nicht erfolgreich!'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 10000;
    },
    mapOrderTypeToLabel(orderType) {
      return OrderTypeUtil.mapOrderTypeToLabel(orderType);
    }
  }
};
</script>

<style scoped lang="scss"></style>
