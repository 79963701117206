<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <DisplayContainer label="Vertragsinformationen">
            <ContractInformation
              class="margin-bottom-1"
              :contractId="contractId"
              :customerId="customerId"
              :address="invoiceAddress"
              :activationDateOfContract="contractDates.activeDate"
              :deactivationDateOfContract="contractDates.deactiveDate"
            />
          </DisplayContainer>
          <DisplayContainer label="Deaktivierte Rufnummern">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Rufnummer</th>
                    <th>Rufnummerntyp</th>
                    <th>Deaktivierungsdatum</th>
                    <th>Abgebender Provider</th>
                    <th>WBCI-ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="numberInfo in deactivatedNumbers"
                    :key="numberInfo.number"
                  >
                    <td>{{ numberInfo.number }}</td>
                    <td>{{ numberInfo.category }}</td>
                    <td>
                      {{
                        numberInfo.deactivationDate
                          ? numberInfo.deactivationDate
                          : '-'
                      }}
                    </td>
                    <td>
                      {{
                        numberInfo.previousCarrier
                          ? `${numberInfo.previousCarrier.name} (${numberInfo.previousCarrier.routingId})`
                          : '-'
                      }}
                    </td>
                    <td>
                      <TextLink
                        v-if="numberInfo.wbciId"
                        linkType="ocs"
                        :linkObject="numberInfo.wbciId"
                      >
                        {{ numberInfo.wbciId }}
                      </TextLink><span v-else>-</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              v-if="deactivationCategories.manuallyPorted.length > 0"
              class="blocker"
            >
              <div>
                <v-icon color="var(--goe-color-error)">
                  mdi-alert
                </v-icon>
                <div>
                  Manuell portierte Rufnummern können nicht automatisch
                  zurückgegeben werden und müssen manuell behandelt werden!
                </div>
              </div>
            </div>
            <div
              v-if="deactivationCategories.automaticallyPorted.length > 0"
              class="blocker"
            >
              <div>
                <v-icon color="var(--goe-fontColor-highlight)">
                  mdi-alert
                </v-icon>
                <div>
                  Für WBCI-Portierungen wurde bereits eine Z-Meldung abgesetzt.
                </div>
              </div>
            </div>
            <div
              v-if="deactivationCategories.goetelOwned.length > 0"
              class="blocker"
            >
              <div>
                <v-icon color="var(--goe-fontColor-highlight)">
                  mdi-alert
                </v-icon>
                <div>
                  Neue Rufnummern wurden bereits in den Rufnummernpool
                  zurückgegeben.
                </div>
              </div>
            </div>
            <div
              v-if="deactivationCategories.existingNumbers.length > 0"
              class="blocker"
            >
              <div>
                <v-icon color="var(--goe-fontColor-highlight)">
                  mdi-alert
                </v-icon>
                <div>
                  Der Ursprung der Rufnummern konnte nicht bestimmt werden, sie
                  müssen daher manuell behandelt werden!
                </div>
              </div>
            </div>
          </DisplayContainer>
          <DisplayContainer label="Provisionierte Rufnummern">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Rufnummer</th>
                    <th>Rufnummerntyp</th>
                    <th>Provisionierungsdatum</th>
                    <th>Abgebender Provider</th>
                    <th>WBCI-ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="numberInfo in provisioningNumbers"
                    :key="numberInfo.number"
                  >
                    <td>{{ numberInfo.number }}</td>
                    <td>{{ numberInfo.category }}</td>
                    <td>
                      {{ numberInfo.provisioningDate }}
                    </td>
                    <td>
                      {{
                        numberInfo.previousCarrier
                          ? `${numberInfo.previousCarrier.name} (${numberInfo.previousCarrier.routingId})`
                          : '-'
                      }}
                    </td>
                    <td>
                      <TextLink
                        v-if="numberInfo.wbciId"
                        linkType="ocs"
                        :linkObject="numberInfo.wbciId"
                      >
                        {{ numberInfo.wbciId }}
                      </TextLink><span v-else>-</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </DisplayContainer>
          <!-- Restliche, zu provisionierende Rufnummern anzeigen -->
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="isLoading || !claimed"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import TaskBlocker from '@/task/TaskBlocker.vue';
import ContractInformation from '@/components/ContractInformation.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import TextLink from '@/components/elements/TextLink.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ActionCheckDeactivatedNumbers',
  components: {
    TaskBlocker,
    ContractInformation,
    DisplayContainer,
    DataDisplay,
    TextLink
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    contractId: null,
    customerId: null,
    addressDataLoading: true,
    contractDatesLoading: true,
    invoiceAddress: null,
    deactivationCategories: null,
    activeCategories: null,
    provisioningNumbers: [],
    deactivatedNumbers: [],
    contractDates: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading || this.contractDatesLoading;
    },
    taskDataLoading() {
      return this.addressDataLoading;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.addressDataLoading = true;
      this.contractDatesLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.contractId = resp.data.contractId.value;
          this.customerId = resp.data.customerId.value;
          this.deactivationCategories =
            resp.data.deactivatedNumberCategories.value;
          this.activeCategories = resp.data.activeNumberCategories.value;
          this.provisioningNumbers = this.transformDeactivationCategories(
            this.activeCategories,
            resp.data.currentWbciProcessId.value
          );
          this.deactivatedNumbers = this.transformDeactivationCategories(
            this.deactivationCategories,
            resp.data.currentWbciProcessId.value
          );
          this.loadInvoiceAddress(this.contractId);
          this.loadContractDates(this.contractId);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        );
    },
    loadInvoiceAddress: function (contractId) {
      HTTP.get(`/customer/addressByType/${contractId}/INVOICE`)
        .then((resp) => {
          this.invoiceAddress = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.addressDataLoading = false;
        });
    },
    loadContractDates: function (contractId) {
      HTTP.get(`/contracts/dates/${contractId}`)
        .then((resp) => {
          this.contractDates = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.contractDatesLoading = false;
        });
    },
    transformDeactivationCategories: function (deactivationCategories, wbciId) {
      let deactivatedNumbers = deactivationCategories.automaticallyPorted.map(
        (number) => this.mapNumberToObject(number, 'WBCI-Portierung', wbciId)
      );
      deactivatedNumbers = deactivatedNumbers.concat(
        deactivationCategories.goetelOwned.map((number) =>
          this.mapNumberToObject(number, 'Neue Rufnummer', wbciId)
        )
      );
      deactivatedNumbers = deactivatedNumbers.concat(
        deactivationCategories.manuallyPorted.map((number) =>
          this.mapNumberToObject(number, 'Manuelle Portierung', wbciId)
        )
      );
      deactivatedNumbers = deactivatedNumbers.concat(
        deactivationCategories.existingNumbers.map((number) =>
          this.mapNumberToObject(number, 'Bestehende Rufnummer', wbciId)
        )
      );
      return deactivatedNumbers;
    },
    mapNumberToObject: function (number, category, wbciId) {
      return {
        number: number.phoneNumber,
        category,
        deactivationDate:
          number.deactivationDate === null
            ? '?'
            : TimeUtility.formatLocalDate(number.deactivationDate),
        previousCarrier: number.previousCarrier,
        wbciId: category === 'WBCI-Portierung' ? wbciId : null,
        provisioningDate: TimeUtility.formatLocalDate(number.provisioningDate)
      };
    },
    completeTask: function () {
      HTTP.post(`/task/${this.data.id}/complete`, {
        variables: {}
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Die Aufgabe wurde erfolgreich abgeschlossen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden.'
            )
          )
        );
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}

.blocker {
  display: block;
  width: 100%;
  height: 100%;
  left: var(--goe-spacing-0);
  right: auto;
  bottom: auto;
  opacity: 0.7;
}

.blocker > div {
  font-size: var(--goe-fontSize-normal);
  line-height: var(--goe-spacing-5);
  color: var(--goe-fontColor-dark);
  user-select: none;
  display: flex;
}
.blocker > div > div {
  margin-left: var(--goe-spacing-1);
}
</style>
