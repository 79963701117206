<template>
  <div class="process-wrapper">
    <div>
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>
      <div v-if="!isLoading && selocaFeedbackFile">
        <display-container
          :label="`Bestellinformationen - ${selocaFeedbackFile.orderNumber}`"
          class="my-4"
        >
          <div class="details">
            <data-display label="Kundennummer">
              <a
                target="_blank"
                :href="`/support-start?page=Kunden%C3%BCbersicht&system=VarioCRM&customerId=${selocaFeedbackFile.customerNumber}`"
              >
                {{ selocaFeedbackFile.customerNumber }}
              </a>
            </data-display>
            <data-display label="Vertragsnummer">
              <span>{{ selocaFeedbackFile.contractNumber }}</span>
            </data-display>
            <data-display label="Paket Empfänger">
              <span>{{ getRecipient() }}</span><br />
              <span>{{ getDeliveryAddress(1) }}</span><br />
              <span>{{ getDeliveryAddress(2) }}</span>
            </data-display>
            <data-display label="Tracking Link">
              <a
                target="_blank"
                :href="trackingUrl"
              >
                {{ selocaFeedbackFile.logisticCompany }} - {{ selocaFeedbackFile.trackingId }}
              </a>
            </data-display>
          </div>
        </display-container>
        <display-container label="Paket Inhalt">
          <v-data-table
            :headers="tableHeaders"
            :items="selocaFeedbackFile.sentArticles"
            :items-per-page="-1"
            disable-sort
          >
          </v-data-table>
        </display-container>
        <div class="buttons-wrapper">
          <v-btn
            :disabled="isLoading || !claimed"
            color="primary"
            large
            @click="completeTask()"
          >
            Task abschließen
          </v-btn>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import { getTrackingUrl } from '@/util/SelocaUtil.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionHandleManuallyOrderedArticle',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
    Loader: () => import('@/components/Loader.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      customerId: null,
      contractId: null,
      selocaFeedbackFile: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    articleCount() {
      return this.selocaFeedbackFile.sentArticles.length;
    },
    articleAmount() {
      return this.selocaFeedbackFile.sentArticles.reduce(
        (accumulator, current) => accumulator + current.anzahl,
        0
      );
    },
    tableHeaders() {
      const headers = [];
      if (this.articleAmount > this.articleCount) {
        headers.push({ text: 'Anzahl', value: 'anzahl', width: '100px' });
      }

      headers.push(
        ...[
          { text: 'Artikel', value: 'modell' },
          { text: 'Artikel Nummer', value: 'artikelNummer' },
          { text: 'Seriennummer', value: 'serienNummer' },
          { text: 'ONT Seriennummer', value: 'resource.ontSerialNumber' },
          { text: 'CWMP Account', value: 'cwmpAccount' }
        ]
      );
      return headers;
    },
    trackingUrl() {
      return this.getTrackingUrl(
        this.selocaFeedbackFile.logisticCompany,
        this.selocaFeedbackFile.trackingId
      );
    }
  },
  mounted: function () {
    this.loadTaskData();
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;
      await this.loadTaskVariables();
    },
    loadTaskVariables: function () {
      return HTTP.get(`/task/${this.data.id}/variables`)
        .then(async ({ data }) => {
          this.customerId = data.customerId.value;
          this.contractId = data.contractId.value;
          this.selocaFeedbackFile = data.selocaFeedbackFile.value;
          const serialNumbers = this.selocaFeedbackFile.sentArticles.map(a => a.serienNummer).filter(a => !!a);
          await HTTP.post('/device/cpe/findBySerialNumbers', serialNumbers)
            .then(({ data: cpes }) => {
              cpes.forEach((c) => {
                const i = this.selocaFeedbackFile.sentArticles.findIndex(a => a.serienNummer === c.serialNumber);
                if (i < 0) return;
                this.selocaFeedbackFile.sentArticles[i].resource = c;
              });
            });
        })
        .catch(({ response }) => {
          this.showToast(
            `Error loading task variables HTTP Status Code ${response.status}`
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTrackingUrl(supplier, trackingId) {
      return getTrackingUrl(supplier, trackingId);
    },
    getRecipient() {
      const { salutation, firstName, middleName, lastName } = this.selocaFeedbackFile;
      return `${(salutation ?? 'keine') !== 'keine' ? salutation : ''} ${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`.trim();
    },
    getDeliveryAddress(line) {
      const { street, houseNumber, houseNumberAddition, zip, city } =
        this.selocaFeedbackFile;
      if (line === 1) {
        return `${street} ${houseNumber}${houseNumberAddition ?? ''}`;
      }
      if (line === 2) {
        return `${zip} ${city}`;
      } else return '';
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {})
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>

<style scoped>
.details {
  display: grid;
  grid-template-columns: calc(50% - var(--goe-spacing-0_5)) calc(
      50% - var(--goe-spacing-0_5)
    );
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}
</style>
