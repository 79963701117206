<template>
  <div>
    <v-data-table
      v-model="selectedModel"
      :headers="tableHeaders"
      :items="availableModels"
      :search="modelSearchInput"
      item-key="id"
      class="elevation-1"
      show-select
      :single-select="true"
      show-expand
      :single-expand="false"
      :disable-sort="disabled"
      :disable-pagination="disabled"
      :disable-filtering="disabled"
      dense
      :expanded.sync="expandedRows"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="modelsLoading"
      loading-text="Daten werden geladen..."
      no-data-text="Es sind keine Einträge vorhanden."
      no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        'items-per-page-text': 'Zeilen pro Seite:',
        'items-per-page-options': [5, 10, 25, 50, -1]
      }"
      :items-per-page="10"
    >
      <template #top>
        <div class="table-top">
          <v-text-field
            v-model="modelSearchInput"
            append-icon="mdi-magnify"
            label="Artikelsuche"
            single-line
            dense
            hide-details
            :disabled="disabled"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip v-if="!inputIsValid" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert-circle
              </v-icon>
            </template>
            <span> Es muss ein Artikel ausgewählt werden</span>
          </v-tooltip>
          <div v-else></div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="disabled"
                small
                icon
                v-bind="attrs"
                @click="resetInput()"
                v-on="on"
              >
                <v-icon> mdi-restore </v-icon>
              </v-btn>
            </template>
            <span> Auswahl zurücksetzen</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :disabled="disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.updated`]="{ item }">
        {{ formatTime(item.updated) }}
      </template>
      <template v-slot:[`item.currentStock`]="{ item }">
        <div class="remaining-stock-display">
          {{ item.currentStock }}

          <v-tooltip
            v-if="item.stockIsLow || item.stockOfRequiredAccessoryIsLow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                :color="item.stockIsLow === true ? 'red' : 'orange'"
                v-on="on"
              >
                mdi-alert-outline
              </v-icon>
            </template>

            <span
              v-if="
                item.minimalStockOfAccessories !== undefined &&
                  item.currentStock > item.minimalStockOfAccessories
              "
            >
              Noch {{ item.currentStock }} verfügbare Artikel. Davon
              {{ item.minimalStockOfAccessories }} vollständig für Versand
              verfügbar.
            </span>
            <span v-else>
              Nur noch {{ item.currentStock }} verfügbare Artikel.
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <template
          v-if="item.requiredAccessories && item.requiredAccessories.length > 0"
        >
          <td v-for="header in headers" :key="header.value">
            <div>
              <div
                v-for="actionObj in item.requiredAccessories"
                :key="actionObj.id"
              >
                <div v-for="prop in Object.keys(actionObj)" :key="prop.value">
                  <div v-if="prop == header.value">
                    <span v-if="prop == 'updated'">
                      {{ formatTime(actionObj[header.value]) }}
                    </span>
                    <div
                      v-else-if="prop == 'currentStock'"
                      class="remaining-stock-display"
                    >
                      {{ actionObj[header.value] }}

                      <v-tooltip v-if="actionObj.stockIsLow" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            color="red"
                            v-on="on"
                          >
                            mdi-alert-outline
                          </v-icon>
                        </template>
                        <span>
                          Nur noch
                          {{ actionObj['currentStock'] }}
                          verfügbare Artikel.
                        </span>
                      </v-tooltip>
                    </div>
                    <span v-else> {{ actionObj[header.value] }} </span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </template>
        <td v-else :colspan="headers.length">
          Keine benötigten Artikel
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import lodash from 'lodash';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ModelSelection',
  props: {
    disabled: {
      // Disables the selections as long as the parent component is not active and therefore
      // does not allow any userinput
      type: Boolean,
      required: false,
      default: false
    },
    // If true an error message will be displayed in case there was no selection made.
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    deviceType: {
      // Can be either 'RTR', 'RTR_ACS', 'ONT', 'ONT_RTR_ACS' or 'STB'
      type: String,
      required: false,
      default: null
    },
    presentModelData: {
      // The model that is already present. If this is not null or undefined the model selection will
      // be reset to this value. If there is no previously selected model this will be the default value
      // Can be either an object with the fields of an existing model or null
      type: Object,
      required: false,
      default: undefined
    },
    previouslySelectedModel: {
      // Previously selected model. When loaded and not undefined this is the default value for the selection
      // Can be either an object with the fields of an existing model or null
      type: Object,
      required: false,
      default: undefined
    }
  },
  data: function () {
    return {
      modelsLoading: false,
      presentModel: null,
      availableModels: [],
      selectedModel: undefined,
      modelSearchInput: '',
      tableHeaders: [
        { value: 'data-table-select' },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'articleName',
          class: 'title-attr'
        },
        { text: 'Artikelnummer', value: 'articleNumber' },
        { text: 'Verbleibende Menge', value: 'currentStock' },
        { text: 'Letztes Update', value: 'updated' },
        { value: 'data-table-expand' }
      ],
      expandedRows: [],
      sortDesc: true,
      sortBy: 'articleName'
    };
  },
  computed: {
    inputIsValid: function () {
      return (
        !this.isRequired ||
        (this.selectedModel !== null &&
          this.selectedModel !== undefined &&
          this.selectedModel.length > 0)
      );
    }
  },
  watch: {
    presentModelData: {
      handler(newModelData, oldValue) {
        if (newModelData && lodash.isEqual(newModelData, oldValue) === false) {
          const presentModel = lodash.cloneDeep(newModelData);
          this.presentModel = [
            {
              id: presentModel.id,
              articleNumber: presentModel.articleNumber,
              articleName: presentModel.articleName,
              currentStock: presentModel.currentStock,
              requiredAccessories: presentModel.requiredAccessories
            }
          ];
          if (this.previouslySelectedModel === undefined) {
            this.selectedModel = this.presentModel;
            this.modelSearchInput = '';
          }
        }
      },
      immediate: true
    },
    previouslySelectedModel: {
      handler(newModelData, oldValue) {
        if (
          newModelData != null &&
          lodash.isEqual(newModelData, oldValue) === false
        ) {
          const previouslySelectedModel = lodash.cloneDeep(newModelData);

          this.selectedModel = [
            {
              id: previouslySelectedModel.id,
              articleNumber: previouslySelectedModel.articleNumber,
              articleName: previouslySelectedModel.articleName,
              currentStock: previouslySelectedModel.currentStock,
              requiredAccessories: previouslySelectedModel.requiredAccessories
            }
          ];
          this.modelSearchInput = '';
        } else if (newModelData === null) {
          this.selectedModel = undefined;
        }
      },
      immediate: true
    },
    selectedModel: {
      immediate: true,
      handler(newModel, _) {
        const newValue = this.extractPresentModelFromArray(newModel);
        this.addPresentModelToAvailableModelsIfRequired();
        this.$emit('selectedModelChanged', newValue);
      }
    },
    deviceType: {
      immediate: true,
      handler(newValue, _) {
        if (newValue) {
          this.fetchModels(newValue);
        } else {
          this.availableModels = [];
          this.resetInput();
        }
      }
    }
  },
  mounted: function () {
    this.fetchModels(this.deviceType);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    fetchModels(deviceType) {
      if (deviceType) {
        this.modelsLoading = true;
        HTTP.get('/seloca/availableArticles', {
          params: { deviceType: deviceType }
        })
          .then((response) => {
            this.availableModels = response.data.map((article) => {
              const minimalStockOfAccessories = lodash.minBy(
                article.requiredAccessories,
                function (o) {
                  return o.currentStock;
                }
              )?.currentStock;
              return {
                ...article,
                minimalStockOfAccessories: minimalStockOfAccessories
              };
            });
            this.addPresentModelToAvailableModelsIfRequired();
          })
          .catch((e) => {
            this.showMessage(
              'error',
              'Die verfügbaren Geräte konnten nicht geladen werden!'
            );
          })
          .finally(() => {
            this.modelsLoading = false;
          });
      }
    },
    extractPresentModelFromArray(presentModel) {
      let newValue = null;
      if (
        presentModel != null &&
        presentModel !== undefined &&
        presentModel.length > 0
      ) {
        newValue = presentModel[0];
      }
      return newValue;
    },
    addPresentModelToAvailableModelsIfRequired() {
      const presentModel = this.extractPresentModelFromArray(this.presentModel);
      if (
        presentModel != null &&
        presentModel !== undefined &&
        !this.availableModels.includes(presentModel)
      ) {
        // Add present model
        this.availableModels.push(presentModel);
      }
    },
    resetInput() {
      if (this.presentModel !== null && this.presentModel !== undefined) {
        this.modelSearchInput = '';
        this.selectedModel = [this.presentModel];
      } else {
        this.selectedModel = undefined;
      }
    },
    formatTime(localDateTime) {
      return TimeUtility.formatDateTime(localDateTime);
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-body {
  display: grid;
  grid-template-columns: max-content auto;
}
.table-top {
  display: grid;
  grid-template-columns: 50% auto 40px 20px;
  padding: 10px 25px 10px 15px;
}
.remaining-stock-display {
  display: flex;
  align-items: center;
}
</style>
