<template>
  <div class="article">
    <v-data-table
      :headers="filteredHeaders"
      :items="modifiedArticles"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-if="updatedAt != undefined" #top>
        <v-toolbar flat>
          <v-toolbar-title>
            Artikelverfügbarkeit vom {{ formatDateTime(updatedAt) }}
          </v-toolbar-title>
        </v-toolbar>
      </template>

      <!--
      <template v-slot:item.articleName="{ item }">
        <td width="20%">
          {{ item.articleName }}
        </td>
      </template>
      -->
    </v-data-table>
  </div>
</template>

<script>
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ArticleStockTable',
  components: {},
  props: {
    show: {
      type: Array,
      required: false,
      default: () => [
        'articleName',
        'articleNumber',
        'updated',
        'minimumStock',
        'currentStock'
      ]
    },
    articles: { type: Array, required: true },
    updatedAt: { type: Date, required: false, default: undefined }
  },
  data: () => ({
    headers: [
      { text: 'Artikel', value: 'articleName', align: 'start' },
      { text: 'Nummer', value: 'articleNumber' },
      { text: 'Zuletzt aktualisiert', value: 'updated' },
      { text: 'Mindestmenge', value: 'minimumStock' },
      { text: 'Verfügbar', value: 'currentStock' },
      { text: 'In Versendung', value: 'orderedNumber' }
    ]
  }),
  computed: {
    filteredHeaders() {
      return this.headers.filter((h) => this.show.includes(h.value));
    },
    modifiedArticles() {
      return this.articles.map((a) => {
        var tmp = Object.assign({}, a);
        tmp.updated = this.formatDateTime(a.updated);
        return tmp;
      });
    }
  },
  methods: {
    formatDateTime(dateTime) {
      return dateTime ? TimeUtility.formatDateTimeWithoutUhr(dateTime) : '-';
    }
  }
};
</script>

<style scoped lang="scss">
.article table thead tr th:mth-child(1) {
  width: 250px;
}
.article table thead tr th:mth-child(2) {
  width: 150px;
}
</style>
