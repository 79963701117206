<template>
  <div class="customerData-wrapper">
    <div class="customerData">
      <div class="salutation-wrapper">
        <div>Anrede</div>

        <div>
          <div>
            <Checkbox
              :isChecked="salutation === 'MISTER'"
              @onCheckedToggle="salutation = 'MISTER'"
            />
            <span>Herr</span>
          </div>
          <div>
            <Checkbox
              :isChecked="salutation === 'COMPANY'"
              @onCheckedToggle="salutation = 'COMPANY'"
            />
            <span>Firma</span>
          </div>
          <div>
            <Checkbox
              :isChecked="salutation === 'MISS'"
              @onCheckedToggle="salutation = 'MISS'"
            />
            <span>Frau</span>
          </div>
          <div>
            <Checkbox
              :isChecked="salutation === 'ALL'"
              @onCheckedToggle="salutation = 'ALL'"
            />
            <span>Eheleute</span>
          </div>
        </div>
      </div>

      <div v-if="salutation !== 'COMPANY'" class="input-row">
        <GoetelInput
          isMandatory
          description="Vorname"
          type="text"
          inputId="firstname"
          :value="firstname"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="firstname = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          isMandatory
          description="Nachname"
          type="text"
          inputId="lastname"
          :value="lastname"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="lastname = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div v-if="salutation !== 'COMPANY'" class="input-row">
        <GoetelInput
          isMandatory
          description="Geburtsdatum"
          type="date"
          inputId="birthday"
          :value="birthday"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          min="1920-01-01"
          :max="maxDate"
          @onChange="birthday = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          isMandatory
          description="E-Mail"
          type="text"
          inputId="email"
          :value="email"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/"
          @onChange="email = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div v-if="salutation === 'COMPANY'" class="input-row">
        <GoetelInput
          isMandatory
          description="Firmenname"
          type="text"
          inputId="company"
          :value="company"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="company = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          isMandatory
          description="E-Mail"
          type="text"
          inputId="email"
          :value="email"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/"
          @onChange="email = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div class="input-row">
        <GoetelInput
          isMandatory
          description="Telefonnummer"
          type="text"
          inputId="phoneNumber"
          :value="phoneNumber"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/[+]*[0-9]+$/"
          @onChange="phoneNumber = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          description="Handynummer"
          type="text"
          inputId="mobileNumber"
          :value="mobileNumber"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="[]"
          :regExp="/[+]*[0-9]+$/"
          @onChange="mobileNumber = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="() => null"
        />
      </div>

      <div class="input-row-max-content-right">
        <GoetelInput
          isMandatory
          description="Straße"
          type="text"
          inputId="street"
          :value="street"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="street = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          isMandatory
          description="Hausnummer"
          type="text"
          inputId="houseNo"
          customClass="small"
          :value="houseNo"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/[0-9]+[a-zA-Z]*$/"
          @onChange="houseNo = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div class="input-row-max-content-left">
        <GoetelInput
          isMandatory
          description="PLZ"
          type="text"
          inputId="cityCode"
          :value="cityCode"
          customClass="small"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/^[0-9]{5}$/"
          @onChange="cityCode = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          isMandatory
          description="Ort + Ortsteil"
          type="text"
          inputId="city"
          :value="city"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="city = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div class="group-description">
        <span>Zahlungsmethode</span>
        <toggle-button
          v-model="payByDirectDebit"
          :labels="{ checked: 'SEPA', unchecked: 'Rechnung' }"
          :color="{ checked: '#0CF0B8', unchecked: '#777777' }"
        />
      </div>
      <div v-if="payByDirectDebit == true" class="input-row">
        <GoetelInput
          isMandatory
          description="Kontoinhaber"
          type="text"
          inputId="accountOwner"
          :value="accountOwner"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          @onChange="accountOwner = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
      </div>

      <div v-if="payByDirectDebit == true" class="input-row">
        <GoetelInput
          isMandatory
          description="IBAN"
          type="text"
          inputId="iban"
          :value="iban"
          :currentErrors="requiredDataErrors"
          :visitedInputFields="requiredDataVisited"
          :regExp="/[a-zA-Z]{2}[0-9]+$/"
          @onChange="iban = $event"
          @onError="requiredDataErrors = $event"
          @onVisited="requiredDataVisited = $event"
        />
        <GoetelInput
          description="BIC"
          type="text"
          inputId="bic"
          :value="bic"
          :currentErrors="requiredDataErrors"
          :regExp="/[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$/"
          @onChange="bic = $event"
          @onError="requiredDataErrors = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GoetelInput from '@/components/elements/GoetelInput.vue';
import Checkbox from '@/components/elements/Checkbox.vue';

export default {
  name: 'IncomingHouseConnectionOrderCustomerData',
  components: {
    GoetelInput,
    Checkbox
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null
    },
    submitDescription: {
      type: String,
      required: false,
      default: 'Änderungen übernehmen'
    }
  },
  data: () => ({
    maxDate: null,
    salutation: null,
    firstname: null,
    lastname: null,
    company: null,
    street: null,
    houseNo: null,
    cityCode: null,
    city: null,
    birthday: null,
    phoneNumber: null,
    mobileNumber: null,
    email: null,
    payByDirectDebit: false,
    iban: null,
    bic: null,
    accountOwner: null,
    orderData: null,
    requiredDataErrors: [],
    requiredDataVisited: [],
    billingDataErrors: [],
    billingDataVisited: [],
    deliveryDataErrors: [],
    deliveryDataVisited: []
  }),
  created: function () {
    // Setup the date input
    var current = new Date();
    var day = current.getDate();
    var month = current.getMonth() + 1;
    var year = current.getFullYear();

    this.maxDate = `${year}-${month.length < 10 ? '0' : ''}${month}-${
      day.length < 10 ? '0' : ''
    }${day}`;

    if (this.data !== null) {
      // CUSTOMER DATA
      const contactAddress = this.data.contactAddress;
      this.requiredDataVisited = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]; // Dummy visited => default is valid

      this.salutation = contactAddress.salutation;
      this.firstname = contactAddress.firstName;
      this.lastname = contactAddress.lastName;
      this.phoneNumber = contactAddress.phone;
      this.mobileNumber = contactAddress.phone2;
      this.birthday = contactAddress.birthDate;
      this.email = contactAddress.email;
      this.street = contactAddress.street;
      this.houseNo = `${contactAddress.houseno}${contactAddress.housenoSuppl}`;
      this.cityCode = contactAddress.cityCode;
      this.city = contactAddress.city;

      // SEPA
      this.iban = this.data.sepa.iban;
      this.bic = this.data.sepa.bic;
    }
  },
  methods: {
    validate: function () {
      let isValid =
        this.requiredDataVisited.length >= 10 &&
        this.requiredDataErrors.length === 0 &&
        this.salutation !== null;

      if (this.differentDeliveryInfo === true) {
        isValid =
          isValid &&
          this.deliveryDataVisited.length >= 4 &&
          this.deliveryDataErrors.length === 0;
      }

      if (this.differentBillingInfo === true) {
        isValid =
          isValid &&
          this.billingDataVisited.length >= 6 &&
          this.billingDataErrors.length === 0;
      }
      this.$emit('newHouseConnectionOrderValid', isValid);
    },
    buildCustomerData: function () {
      let firstName;
      let lastName;
      let company;

      if (this.salutation === 'COMPANY') {
        firstName = null;
        lastName = null;
        company = this.company;
      } else {
        firstName = this.firstname;
        lastName = this.lastname;
        company = null;
      }

      let sepa = null;
      if (this.payByDirectDebit === true) {
        sepa = {
          iban: this.iban,
          bic: this.bic,
          owner: this.owner
        };
      }

      this.$emit('updatedOrder', {
        invoiceAddress: {
          salutation: this.salutation,
          firstName: firstName,
          lastName: lastName,
          company: company,
          street: this.street,
          houseNumber: this.houseNo,
          zipCode: this.cityCode,
          city: this.city,
          birthday: this.birthday,
          phoneNumber: this.phoneNumber,
          mobileNumber: this.mobileNumber,
          email: this.email
        },
        sepa: sepa,
        productId: 100049
      });
    }
  }
};
</script>

<style scoped>
.customerData {
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
}

.salutation-wrapper {
  margin-bottom: var(--goe-spacing-2);
}

.salutation-wrapper > div:nth-child(1) {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
}

.salutation-wrapper > div:nth-child(2) {
  background: var(--goe-background-highlight);
  padding: var(--goe-spacing-1) var(--goe-spacing-1) var(--goe-spacing-0)
    var(--goe-spacing-1);
  display: grid;
  grid-template-columns: 50% 50%;
}

.salutation-wrapper > div:nth-child(2) > div {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: var(--goe-spacing-1);
  margin-left: var(--goe-spacing-1);
  margin-bottom: var(--goe-spacing-1);
  font-size: var(--goe-fontSize-small);
}

.salutation-wrapper > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.title {
  margin-bottom: var(--goe-spacing-2);
}

.group-description {
  display: grid;
  grid-template-columns: auto max-content var(--goe-spacing-5_5);
  margin-bottom: var(--goe-spacing-2);
}

.group-description .toggle-description {
  color: var(--goe-fontColor-light);
  font-size: var(--goe-fontSize-small);
  margin: auto var(--goe-spacing-1);
}

.customerData-wrapper {
  display: grid;
  grid-template-rows: calc(100% - var(--goe-spacing-6)) max-content;
  overflow: hidden;
  height: calc(100% - var(--goe-spacing-6));
  grid-gap: var(--goe-spacing-1);
}

.customerData-wrapper > div {
  overflow: auto;
}

.empty {
  border-bottom: var(--goe-borderSize-normal) solid var(--goe-borderColor-clear);
  padding-bottom: var(--goe-spacing-1);
}

.buttons-wrapper {
  margin-left: var(--goe-spacing-0);
  margin-right: var(--goe-spacing-0);
}
</style>
