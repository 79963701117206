<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div class="score-circle-wrapper">
            <vue-ellipse-progress
              :color="color"
              :progress="100"
              emptyColor="#f7f7f7"
              :thickness="5"
              lineMode="out 10"
              :size="150"
            >
              <span>{{ score }}</span>
            </vue-ellipse-progress>
          </div>

          <div class="group">
            <div>Vertragsnummer</div>
            <div>
              <div>{{ contractId }}</div>
            </div>
          </div>

          <CustomerDetails
            v-if="customerDetails !== null"
            :data="customerDetails"
            parseSalutation
            title="Kundeninformationen"
          />

          <div class="group">
            <div>Produkte</div>
            <div>
              <div class="label">
                Ausgewählte Produkte
              </div>
              <div
                v-for="product in products"
                :key="product.id"
                class="product-item item"
              >
                <div class="name">
                  {{ product.name }}
                </div>
                <div class="description">
                  {{
                    product.price.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    })
                  }}
                  {{ ` (${product.periodic ? 'RC' : 'NRC'})` }}
                </div>
                <div v-if="product.description && product.description !== ''">
                  {{ product.description }}
                </div>
              </div>
              <div v-if="products && products.length > 1">
                <div class="label">
                  Gesamtpreis
                </div>
                <div>
                  {{
                    totalPrice.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="secondary"
          large
          :disabled="!claimed"
          @click="decide(false)"
        >
          Ablehnen
        </v-btn>
        <v-btn
          large
          color="primary"
          :disabled="!claimed"
          @click="decide(true)"
        >
          Akzeptieren
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import CustomerDetails from '@/components/CustomerDetails.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

const DANGER_COLOR = 'FF0000';
const WARNING_COLOR = '#FFFF00';
const NORMAL_COLOR = '#00FF00';
const UNKNOWN_COLOR = '#606060';

export default {
  name: 'ActionPaymentValidation',
  components: { NormalButton, TaskBlocker, CustomerDetails, Loader },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    color: NORMAL_COLOR,
    creditReport: null,
    score: 0,
    contractId: null,
    customerDetails: null,
    products: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    totalPrice() {
      var total = 0;
      for (var index in this.products) {
        var product = this.products[index];
        console.log(product);
        total = total + product.price;
      }
      return total;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadCustomerDetails(this.data.id);
  },
  methods: {
    calculateColor: function (boniversumColor) {
      switch (boniversumColor) {
        case 'GREY': {
          this.color = UNKNOWN_COLOR;
          break;
        }
        case 'RED': {
          this.color = DANGER_COLOR;
          break;
        }
        case 'YELLOW': {
          this.color = WARNING_COLOR;
          break;
        }
      }
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadCustomerDetails: function (id) {
      this.isLoading = true;
      HTTP.get(`/creditcheck/taskdata/${id}`)
        .then((resp) => {
          console.log(resp);
          this.creditReport = resp.data.creditReport;
          console.log(this.creditReport);
          this.score = this.creditReport.creditCheckScore;
          this.contractId = resp.data.contractId;
          this.products = resp.data.products;
          this.calculateColor(this.creditReport.creditCheckColor);
          // this.transformAddressData(resp.data.invoiceAddress);
          this.customerDetails = resp.data.invoiceAddress;
          this.product = resp.data.invoiceAddress.product;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    decide: function (approved) {
      HTTP.post(`/creditcheck/decision/${this.data.id}/${approved}`)
        .then((resp) => {
          this.showMessage(
            'info',
            `Die Bonität des Kunden wurde erfolgreich ${
              approved ? 'bestätigt!' : 'abgelehnt!'
            }`
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Bonität des Kunden konnte nicht erfolgreich verifiziert werden!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.score-circle-wrapper {
  margin: auto;
  width: max-content;
  user-select: none;
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.loader {
  margin: auto;
}

.description {
  font-size: var(--goe-fontSize-small);
  margin-top: var(--goe-spacing-0_5);
}
</style>
