<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <v-tabs background-color="rgba(0,0,0,0.05)">
            <v-tab
              v-if="potentialHcContractDuplicates.length > 0"
              @click="hcTabSelected = true"
            >
              <v-icon left>
                mdi-home-outline
              </v-icon>
              Hausanschlussauftrag
            </v-tab>
            <v-tab
              v-if="potentialServiceContractDuplicates.length > 0"
              @click="hcTabSelected = false"
            >
              <v-icon left>
                mdi-web
              </v-icon>
              Dienstauftrag
            </v-tab>
          </v-tabs>

          <v-tabs
            v-show="hcTabSelected && potentialHcContractDuplicates.length > 0"
            v-model="selectedHcContractIdTab"
          >
            <v-tab v-for="item in potentialHcContractDuplicates" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs
            v-show="
              !hcTabSelected && potentialServiceContractDuplicates.length > 0
            "
            v-model="selectedServiceContractIdTab"
          >
            <v-tab
              v-for="item in potentialServiceContractDuplicates"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-row style="margin-top: 20px">
            <v-col
              v-if="
                newContractData.installationAddress != null &&
                  newContractData.invoiceAddress != null &&
                  newContractData.products != null
              "
            >
              <DisplayContainer>
                <div
                  class="text-h4"
                  style="color: #209bd8; margin-bottom: 15px"
                >
                  Neuer Auftrag
                </div>
                <DisplayContainer label="Vertrag">
                  <DataDisplay label="ID">
                    <div v-if="hcTabSelected">
                      {{ newHcContractId }}
                    </div>
                    <div v-else>
                      {{ newServiceContractId }}
                    </div>
                  </DataDisplay>
                </DisplayContainer>
                <CustomerDetails
                  v-if="newContractData.invoiceAddress"
                  :data="newContractData.invoiceAddress"
                  :parseSalutation="true"
                  title="Rechnungsadresse"
                />
                <CustomerDetails
                  v-if="newContractData.installationAddress"
                  :data="newContractData.installationAddress"
                  :parseSalutation="true"
                  title="Installationsadresse"
                />
                <DisplayContainer label="Gebuchte Produkte">
                  <AllOrderedProductsTable
                    :allProducts="newContractData.products"
                  />
                </DisplayContainer>
              </DisplayContainer>
            </v-col>
            <v-col
              v-if="
                duplicateContractData.installationAddress != null &&
                  duplicateContractData.invoiceAddress != null &&
                  duplicateContractData.products != null
              "
            >
              <DisplayContainer>
                <div
                  class="text-h4"
                  style="color: #209bd8; margin-bottom: 15px"
                >
                  Potentielles Duplikat
                </div>
                <DisplayContainer label="Vertrag">
                  <DataDisplay label="ID">
                    <div v-if="hcTabSelected">
                      {{
                        potentialHcContractDuplicates[selectedHcContractIdTab]
                      }}
                    </div>
                    <div v-else>
                      {{
                        potentialServiceContractDuplicates[
                          selectedServiceContractIdTab
                        ]
                      }}
                    </div>
                  </DataDisplay>
                </DisplayContainer>
                <CustomerDetails
                  v-if="duplicateContractData.invoiceAddress"
                  :data="duplicateContractData.invoiceAddress"
                  :parseSalutation="true"
                  title="Rechnungsadresse"
                />
                <CustomerDetails
                  v-if="duplicateContractData.installationAddress"
                  :data="duplicateContractData.installationAddress"
                  :parseSalutation="true"
                  title="Installationsadresse"
                />
                <DisplayContainer label="Gebuchte Produkte">
                  <AllOrderedProductsTable
                    :allProducts="duplicateContractData.products"
                  />
                </DisplayContainer>
              </DisplayContainer>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled"
          @click="completeTask(false)"
        >
          Auftrag akzeptieren
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled"
          @click="completeTask(true)"
        >
          Auftrag ablehnen
        </v-btn>
      </div>
      <v-overlay v-if="isLoading">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import TaskBlocker from '../TaskBlocker.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';
import CustomerDetails from '@/components/CustomerDetails.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'CheckPotentialContractDuplicatesTask',
  components: {
    CustomerDetails,
    DataDisplay,
    DisplayContainer,
    TaskBlocker,
    AllOrderedProductsTable
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedHcContractIdTab: 0,
    selectedServiceContractIdTab: 0,
    potentialHcContractDuplicates: [],
    potentialServiceContractDuplicates: [],
    hcTabSelected: true,
    isLoading: true,
    newServiceContractId: undefined,
    newHcContractId: undefined,
    hcorder: {},
    duplicateContractData: {},
    newContractData: {},
    fetchedContractData: new Map(),
    contractProducts: new Map(),
    contractInvoiceAddresses: new Map(),
    contractInstallationAddresses: new Map()
  }),
  computed: {
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  watch: {
    hcTabSelected() {
      this.getDuplicateContractData();
      this.getNewContractData();
    },
    selectedHcContractIdTab() {
      this.getDuplicateContractData();
    },
    selectedServiceContractIdTab() {
      this.getDuplicateContractData();
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id).then(() => {
      const promisses = [];
      this.hcTabSelected = this.potentialHcContractDuplicates.length > 0;
      this.potentialHcContractDuplicates.forEach((contractId) => {
        promisses.push(this.collectDuplicateContractData(contractId));
      });
      this.potentialServiceContractDuplicates.forEach((contractId) => {
        promisses.push(this.collectDuplicateContractData(contractId));
      });
      if (this.potentialHcContractDuplicates.length > 0) {
        promisses.push(this.collectDuplicateContractData(this.newHcContractId));
      }
      if (this.potentialServiceContractDuplicates.length > 0) {
        promisses.push(
          this.collectDuplicateContractData(this.newServiceContractId)
        );
      }
      Promise.allSettled(promisses).then(() => {
        this.potentialHcContractDuplicates.forEach((contractId) => {
          this.buildContractDataObject(contractId);
        });
        this.potentialServiceContractDuplicates.forEach((contractId) => {
          this.buildContractDataObject(contractId);
        });
        this.buildContractDataObject(this.newServiceContractId);
        this.buildContractDataObject(this.newHcContractId);
        this.getDuplicateContractData();
        this.getNewContractData();
        this.isLoading = false;
      });
    });
  },
  methods: {
    getNewContractData() {
      if (this.hcTabSelected) {
        this.newContractData = this.fetchedContractData.get(
          this.newHcContractId
        );
      } else {
        this.newContractData = this.fetchedContractData.get(
          this.newServiceContractId
        );
      }
    },
    getDuplicateContractData() {
      if (this.hcTabSelected) {
        const contractId =
          this.potentialHcContractDuplicates[this.selectedHcContractIdTab];
        this.duplicateContractData = this.fetchedContractData.get(contractId);
      } else {
        const contractId =
          this.potentialServiceContractDuplicates[
            this.selectedServiceContractIdTab
          ];
        this.duplicateContractData = this.fetchedContractData.get(contractId);
      }
    },
    showMessage(type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    collectDuplicateContractData(contractId) {
      return Promise.allSettled([
        this.fetchCustomerInfo(contractId),
        this.fetchContractInfo(contractId),
        this.loadContractProducts(contractId)
      ]);
    },
    loadTaskData(id) {
      this.isLoading = true;
      return this.data.variables.then((data) => {
        if (data.serviceContractHasPotentialDuplicates.value) {
          this.potentialServiceContractDuplicates =
            data.potentialServiceContractDuplicates.value;
          this.newServiceContractId = data.serviceContractId.value;
        }
        if (data.hcContractHasPotentialDuplicates.value) {
          this.potentialHcContractDuplicates =
            data.potentialHcContractDuplicates.value;
          this.newHcContractId = data.houseConnectionContractId.value;
        }
      }).catch((err) => {
        this.showMessage(
          'error',
          ErrorMessageBuilder.buildErrorMessage(
            err.response.data,
            'Die Vertragsdaten für das potentielle Duplikat konnten nicht geladen werden.'
          )
        );
      });
    },
    fetchCustomerInfo(contractId) {
      return HTTP.get(`/customer/addressByType/${contractId}/INVOICE`).then(
        (resp) => {
          this.contractInvoiceAddresses.set(contractId, resp.data);
        }
      );
    },
    fetchContractInfo(contractId) {
      return HTTP.get(
        `/customer/addressByType/${contractId}/INSTALLATION`
      ).then((resp) => {
        this.contractInstallationAddresses.set(contractId, resp.data);
      });
    },
    buildContractDataObject(contractId) {
      const obj = {};
      obj.installationAddress =
        this.contractInstallationAddresses.get(contractId);
      obj.invoiceAddress = this.contractInvoiceAddresses.get(contractId);
      obj.products = this.contractProducts.get(contractId);
      this.fetchedContractData.set(contractId, obj);
    },
    loadContractProducts(contractId) {
      return HTTP.get(`/products/contract/all/${contractId}`).then((resp) => {
        this.contractProducts.set(contractId, resp.data);
      });
    },
    completeTask: function (isDuplicate) {
      HTTP.post(
        `orderTask/duplicateCheckResult/completeTask/${this.data.id}?classifiedAsDuplicate=${isDuplicate}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(() => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    }
  }
};
</script>
<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
.prozess > div {
  overflow: hidden;
}
</style>
