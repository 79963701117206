<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <DisplayContainer>
            <ArticleStockTable
              :articles="selocaStock"
              :updatedAt="selocaStock[0].updated"
            />
          </DisplayContainer>
          <DisplayContainer :label="'Anmerkungen zur Artikelverfügbarkeit'">
            <v-textarea
              ref="memo"
              v-model="memo"
              class="input-field"
              auto-grow
              clearable
              clear-icon="mdi-delete"
              name="memo-input"
              placeholder="Anmerkungen"
              :value="memo"
              :disabled="!claimed"
            />
          </DisplayContainer>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !inputIsValid(memo)"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled"
          @click="completeTask()"
        >
          Warenbestand geprüft
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '../../components/elements/DataDisplay.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import { HTTP } from '@/main/httpClient.js';
import ArticleStockTable from './ArticleStockTable.vue';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  components: {
    TaskBlocker,
    Loader,
    NotificationObject,
    ErrorMessageBuilder,
    DisplayContainer,
    DataDisplay,
    DateDisplay,
    ArticleStockTable,
    TimeUtility
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      selocaStock: null,
      memo: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    },
    label() {
      return (
        'Artikelverfügbarkeit vom ' +
        this.formatDateTime(this.selocaStock[0].lastUpdateOfStock)
      );
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.selocaStock = resp.data.selocaStock.value;
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
          return resp;
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Artikelverfügbarkeit konnte nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      })
        .then((resp) => {
          const message = 'Das Abspeichern des Memos war erfolgreich!.';
          this.showMessage('info', message);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Das Abspeichern des Memos war nicht erfolgreich!'
            )
          )
        );
    },
    completeTask: function () {
      HTTP.post(
        `/selocaTask/checkSelocaStock/${this.data.id}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 5000;
    },
    formatDateTime(dateTime) {
      return dateTime ? TimeUtility.formatDateTime(dateTime) : '-';
    }
  }
};
</script>

<style scoped lang="scss">
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
