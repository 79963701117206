var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.availableModels,"search":_vm.modelSearchInput,"item-key":"id","show-select":"","single-select":true,"show-expand":"","single-expand":false,"disable-sort":_vm.disabled,"disable-pagination":_vm.disabled,"disable-filtering":_vm.disabled,"dense":"","expanded":_vm.expandedRows,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.modelsLoading,"loading-text":"Daten werden geladen...","no-data-text":"Es sind keine Einträge vorhanden.","no-results-text":"Für den angegebenen Ort konnten keine Einträge gefunden werden.","footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      'items-per-page-text': 'Zeilen pro Seite:',
      'items-per-page-options': [5, 10, 25, 50, -1]
    },"items-per-page":10},on:{"update:expanded":function($event){_vm.expandedRows=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-top"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Artikelsuche","single-line":"","dense":"","hide-details":"","disabled":_vm.disabled},model:{value:(_vm.modelSearchInput),callback:function ($$v) {_vm.modelSearchInput=$$v},expression:"modelSearchInput"}}),_c('v-spacer'),(!_vm.inputIsValid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,4073014017)},[_c('span',[_vm._v(" Es muss ein Artikel ausgewählt werden")])]):_c('div'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"small":"","icon":""},on:{"click":function($event){return _vm.resetInput()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-restore ")])],1)]}}])},[_c('span',[_vm._v(" Auswahl zurücksetzen")])])],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":_vm.disabled},on:{"input":function($event){return select($event)}}})]}},{key:"item.updated",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.updated))+" ")]}},{key:"item.currentStock",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"remaining-stock-display"},[_vm._v(" "+_vm._s(item.currentStock)+" "),(item.stockIsLow || item.stockOfRequiredAccessoryIsLow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.stockIsLow === true ? 'red' : 'orange'}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[(
              item.minimalStockOfAccessories !== undefined &&
                item.currentStock > item.minimalStockOfAccessories
            )?_c('span',[_vm._v(" Noch "+_vm._s(item.currentStock)+" verfügbare Artikel. Davon "+_vm._s(item.minimalStockOfAccessories)+" vollständig für Versand verfügbar. ")]):_c('span',[_vm._v(" Nur noch "+_vm._s(item.currentStock)+" verfügbare Artikel. ")])]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
            var headers = ref.headers;
return [(item.requiredAccessories && item.requiredAccessories.length > 0)?_vm._l((headers),function(header){return _c('td',{key:header.value},[_c('div',_vm._l((item.requiredAccessories),function(actionObj){return _c('div',{key:actionObj.id},_vm._l((Object.keys(actionObj)),function(prop){return _c('div',{key:prop.value},[(prop == header.value)?_c('div',[(prop == 'updated')?_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(actionObj[header.value]))+" ")]):(prop == 'currentStock')?_c('div',{staticClass:"remaining-stock-display"},[_vm._v(" "+_vm._s(actionObj[header.value])+" "),(actionObj.stockIsLow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Nur noch "+_vm._s(actionObj['currentStock'])+" verfügbare Artikel. ")])]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(actionObj[header.value])+" ")])]):_vm._e()])}),0)}),0)])}):_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Keine benötigten Artikel ")])]}}],null,true),model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }