<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <DisplayContainer label="Vertragsinformationen">
            <ContractInformation
              class="margin-bottom-1"
              :contractId="contractId"
              :customerId="customerId"
              :address="installationAddress"
              :activationDateOfContract="contractDates.activeDate"
              :deactivationDateOfContract="contractDates.deactiveDate"
            />
            <DataDisplay label="Rufnummern">
              <div
                v-for="(phoneNumber, index) in contractPhoneNumbers"
                :key="index"
              >
                {{ phoneNumber }}
              </div>
            </DataDisplay>
          </DisplayContainer>
          <DisplayContainer label="Gebuchte Produkte">
            <AllOrderedProductsTable :allProducts="contractProducts" />
          </DisplayContainer>
          <DisplayContainer label="CPE-Auswahl">
            <HardwareDeviceSelection
              :deviceType="requiredCpeDeviceType"
              :disabled="!claimed"
              @inputIsValidChanged="inputsInvalid = !$event"
              @selectedDeviceChanged="selectedDevice = $event"
            />
          </DisplayContainer>
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="isLoading || !claimed"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import TaskBlocker from '@/task/TaskBlocker.vue';
import HardwareDeviceSelection from '@/components/deviceProvisioning/HardwareDeviceSelection.vue';
import ContractInformation from '@/components/ContractInformation.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ActionAddCpeId',
  components: {
    TaskBlocker,
    HardwareDeviceSelection,
    ContractInformation,
    AllOrderedProductsTable,
    DisplayContainer,
    DataDisplay
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    contractId: null,
    customerId: null,
    productDataLoading: true,
    addressDataLoading: true,
    contractDataLoading: true,
    phoneNumbersLoading: true,
    cpeProductTypeLoading: true,
    installationAddress: null,
    contractProducts: null,
    requiredCpeDeviceType: null,
    contractPhoneNumbers: null,
    contractDates: null,
    inputsInvalid: false,
    selectedDevice: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading;
    },
    taskDataLoading() {
      return (
        this.addressDataLoading ||
        this.productDataLoading ||
        this.cpeProductTypeLoading ||
        this.phoneNumbersLoading ||
        this.contractDataLoading
      );
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.productDataLoading = true;
      this.addressDataLoading = true;
      this.phoneNumbersLoading = true;
      this.contractDataLoading = true;
      this.cpeProductTypeLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.contractId = resp.data.contractId.value;
          this.customerId = resp.data.customerId.value;
          this.loadInstallationAddress(this.contractId);
          this.loadContractProducts(this.contractId);
          this.loadCpeContractProducts(this.contractId);
          this.loadContractPhoneNumbers(this.contractId);
          this.loadContractDates(this.contractId);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die verfügbaren ONT-Modelle konnten nicht geladen werden!'
            )
          )
        );
    },
    loadInstallationAddress: function (contractId) {
      HTTP.get(`/customer/addressByType/${contractId}/INSTALLATION`)
        .then((resp) => {
          this.installationAddress = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.addressDataLoading = false;
        });
    },
    loadContractProducts: function (contractId) {
      HTTP.get(`/products/contract/all/${contractId}`)
        .then((resp) => {
          this.contractProducts = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.productDataLoading = false;
        });
    },
    loadCpeContractProducts: function (contractId) {
      HTTP.get(`/products/contract/cpe/${contractId}`, {
        params: {
          cpeDeviceCategory: 'ROUTER'
        }
      })
        .then((resp) => {
          this.requiredCpeDeviceType = resp.data.product.requiredDevice;
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.cpeProductTypeLoading = false;
        });
    },
    loadContractPhoneNumbers: function (contractId) {
      HTTP.get(`/contracts/phoneNumbers/${contractId}`)
        .then((resp) => {
          this.contractPhoneNumbers = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.phoneNumbersLoading = false;
        });
    },
    loadContractDates: function (contractId) {
      HTTP.get(`/contracts/dates/${contractId}`)
        .then((resp) => {
          this.contractDates = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.contractDataLoading = false;
        });
    },
    completeTask: function () {
      HTTP.post(
        `/contracts/completeAddCpeResource/${this.data.id}`,
        this.selectedDevice,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          this.showMessage(
            'success',
            'Die Aufgabe wurde erfolgreich abgeschlossen.'
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden.'
            )
          )
        );
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
