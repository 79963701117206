<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <div class="pa-4 mb-2" style="border: 1px solid #ddd">
            <h3
              v-if="
                existingContractData &&
                  existingContractData.contractId &&
                  existingContractData.contractId.value
              "
            >
              Neuer Vertrag {{ existingContractData.contractId.value }}
            </h3>
            <CustomerDetails
              v-if="customerAddress !== null"
              :data="customerAddress"
              parseSalutation
              title="Kundeninformationen"
            />
            <ContractDetails
              v-if="
                existingContractData &&
                  existingContractData.contractId &&
                  existingContractData.contractId.value
              "
              :data="existingContractData"
              title="Vertragsinformationen"
            />
          </div>
          <DisplayContainer
            :label="'Existierende Supply Units an dieser Adresse (AGS-Key)'"
          >
            <v-data-table
              v-model="selectedItem"
              :headers="homeIdTableHeaders"
              :items="existingSupplyUnits"
              item-key="supplyUnit.homeId"
              show-select
              single-select
              return-object
            >
              <template v-slot:[`item.supplyUnit.createdAt`]="{ item }">
                {{ formatDate(item.supplyUnit.createdAt) }}
              </template>
            </v-data-table>
          </DisplayContainer>
          <div v-if="contractId">
            <DisplayContainer :label="'Anmerkungen'">
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Anmerkungen"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="completeTask(false)"
        >
          {{
            selectedItem.length >= 1
              ? 'Ausgewählte Supply Unit verwenden'
              : 'Neue Supply Unit generieren'
          }}
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import ContractDetails from '@/components/ContractDetails.vue';
import Loader from '@/components/elements/Loader.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import TextLink from '@/components/elements/TextLink.vue';
import TaskBlocker from '../TaskBlocker.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  components: {
    TaskBlocker,
    Loader,
    CustomerDetails,
    DatePicker,
    NotificationObject,
    DisplayContainer,
    AllOrderedProductsTable,
    TextLink,
    ErrorMessageBuilder,
    ContractDetails
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      cancellationDialog: false,
      isLoading: false,
      customerAddress: null,
      contractId: null,
      customerId: null,
      ags27: null,
      existingSupplyUnits: [],
      memo: null,
      homeIdTableHeaders: [
        { text: 'HomeId', sortable: true, value: 'supplyUnit.homeId' },
        {
          text: 'HomeId generiert am ',
          sortable: true,
          value: 'supplyUnit.createdAt'
        },
        {
          text: 'Zugewiesener Vertrag',
          sortable: true,
          value: 'supplyUnit.contractId'
        },
        {
          text: 'Kundennummer',
          value: 'contractDto.customerId'
        },
        { text: 'Vorname', value: 'contractDto.addressShortDto.firstName' },
        { text: 'Nachname', value: 'contractDto.addressShortDto.lastName' },
        { text: 'Aktivierungsdatum', value: 'contractDto.activeDate' },
        { text: 'Deaktivierungsdatum', value: 'contractDto.deactiveDate' }
      ],
      selectedItem: [],
      existingContractData: null
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    buttonsDisabled() {
      return this.isLoading || !this.inputIsValid(this.memo);
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      } else {
        return '';
      }
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          // Prefer local customer data to process customer data
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          this.ags27 = resp.data.ags27.value;
          this.existingContractData = resp.data;
        })
        .then((response) => {
          this.fetchAddressInfo(this.contractId);
          this.fetchExistingSupplyUnitsWithContractInfo();
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`)
        .then((res) => {
          this.customerAddress = { ...res.data, customerId: this.customerId };
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        );
    },
    fetchExistingSupplyUnitsWithContractInfo() {
      return HTTP.get(`/orderTask/existingHomeIds?ags27=${this.ags27}`)
        .then((resp) => {
          console.log(resp);
          this.existingSupplyUnits = resp.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        );
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          },
          plannedActivationDateGUI: {
            value: this.plannedActivationDateGUI,
            type: 'String'
          }
        }
      });
    },
    completeTask: function (cancellation) {
      HTTP.post(
        `/orderTask/closeHomeIdUseExistingOrGenerateNewTask/${this.data.id}`,
        {
          selectedSupplyUnit:
            this.selectedItem.length >= 1
              ? this.selectedItem[0].supplyUnit
              : null
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 60;
    }
  }
};
</script>

<style scoped>
.dialog-buttons .v-btn {
  margin-right: 5px;
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}

.loader {
  margin: auto;
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
