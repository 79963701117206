<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerAddress !== null"
            :data="customerAddress"
            parseSalutation
            title="Kundeninformationen"
          />
          <div
            v-for="(portingDataEntry, index) in portingData"
            :key="portingDataEntry.portingProcessInstanceId"
          >
            <div class="group">
              <div>Portierungsdaten Portierungsprozess {{ index + 1 }}</div>
              <div>
                <div class="label">
                  WBCI-Prozess-Typ
                </div>
                <div class="input-row description porting-info-part">
                  <label
                    v-if="
                      portingDataEntry.portingDto
                        .portPhoneNumbersWithCancellation
                    "
                  >Rufnummerportierung mit Kündigung (KUE-MRN)</label>
                  <label v-else>"Reine Rufnummernportierung (RRNP)"</label>
                </div>
                <div class="label">
                  Bisheriger Anbieter
                </div>
                <div class="input-row description porting-info-part">
                  <label>Name: {{ portingDataEntry.currentProvider.name }}</label>
                  <label>ITU Carrier-Code:
                    {{ portingDataEntry.currentProvider.carrierCode }}</label>
                  <label>Portierungskennung:
                    {{ portingDataEntry.currentProvider.routingId }}</label>
                </div>
                <div class="label">
                  Angefragtes Portierungsdatum
                </div>
                <div class="input-row porting-info-part">
                  <label>
                    {{
                      portingDataEntry.portingDto.requestedPortingDate !=
                        undefined
                        ? portingDataEntry.portingDto.requestedPortingDate
                        : '-'
                    }}</label>
                </div>
                <div class="label">
                  Bestätigtes Portierungsdatum
                </div>
                <div class="input-row porting-info-part">
                  <label>
                    {{
                      portingDataEntry.portingDto.confirmedPortingDate !=
                        undefined
                        ? portingDataEntry.portingDto.confirmedPortingDate
                        : '-'
                    }}</label>
                </div>
                <div class="label">
                  Alle Rufnummern portieren?
                </div>
                <div class="input-row porting-info-part">
                  <label>
                    {{
                      portingDataEntry.portingDto.portAllPhoneNumbers
                        ? 'Alle Rufnummern'
                        : 'Ausgewählte Rufnummern'
                    }}
                  </label>
                </div>
                <div class="label">
                  Zu portierende Rufnummern
                </div>
                <ul>
                  <li
                    v-for="number in portingDataEntry.portingDto.phoneNumbers"
                    :key="number"
                  >
                    {{ number }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="group">
            <DisplayContainer :label="'Anmerkungen'">
              <v-textarea
                ref="memo"
                v-model="memo"
                class="input-field"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                name="memo-input"
                placeholder="Anmerkungen zur Stornierung"
                :value="memo"
                :disabled="!claimed"
              />
            </DisplayContainer>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          color="primary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="completeTask()"
        >
          Stornierung abschließen
        </v-btn>
        <v-btn
          color="secondary"
          large
          :disabled="buttonsDisabled || !claimed"
          @click="saveTask()"
        >
          Speichern
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import CustomerDetails from '@/components/CustomerDetails.vue';
import TaskBlocker from '../TaskBlocker.vue';
import Loader from '@/components/elements/Loader.vue';
import NotificationObject from '@/main/NotificationObject.js';
import NumberManagement from '../porting/NumberManagement.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

export default {
  components: {
    CustomerDetails,
    Loader,
    TaskBlocker,
    NumberManagement,
    DisplayContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      customerAddress: null,
      contractId: null,
      customerId: null,
      memo: null,
      portingData: [],
      carrier: []
    };
  },
  computed: {
    buttonsDisabled() {
      return this.isLoading || !this.inputIsValid(this.memo);
    },
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.portingData = resp.data.manualPortingData.value;
          this.contractId =
            resp.data.manualPortingData.value[0].portingDto.contractId;
          this.customerId = resp.data.customerId.value;
          this.carrier = this.portingData.dimariCarrierDto;
          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
          return resp;
        })
        .then(() => {
          return this.fetchAddressInfo(this.contractId);
        })
        .catch((e) => {
          console.log('e', e);
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`).then((res) => {
        this.customerAddress = { ...res.data, customerId: this.customerId };
      });
    },
    inputIsValid: function (memoInput) {
      return memoInput == null || memoInput.length <= 60;
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      });
    },
    completeTask: function () {
      HTTP.post(
        `/contracts/portingCancellation/${this.data.id}?memo=${this.memo}&assignee=${this.data.assignee}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Der Abschluss der Aufgabe war nicht erfolgreich!'
          )
        );
    }
  }
};
</script>

<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
.porting-info-part {
  margin-bottom: var(--goe-spacing-1);
}
</style>
