var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"pa-4 mb-2",staticStyle:{"border":"1px solid #ddd"}},[(
              _vm.existingContractData &&
                _vm.existingContractData.contractId &&
                _vm.existingContractData.contractId.value
            )?_c('h3',[_vm._v(" Neuer Vertrag "+_vm._s(_vm.existingContractData.contractId.value)+" ")]):_vm._e(),(_vm.customerAddress !== null)?_c('CustomerDetails',{attrs:{"data":_vm.customerAddress,"parseSalutation":"","title":"Kundeninformationen"}}):_vm._e(),(
              _vm.existingContractData &&
                _vm.existingContractData.contractId &&
                _vm.existingContractData.contractId.value
            )?_c('ContractDetails',{attrs:{"data":_vm.existingContractData,"title":"Vertragsinformationen"}}):_vm._e()],1),_c('DisplayContainer',{attrs:{"label":'Existierende Supply Units an dieser Adresse (AGS-Key)'}},[_c('v-data-table',{attrs:{"headers":_vm.homeIdTableHeaders,"items":_vm.existingSupplyUnits,"item-key":"supplyUnit.homeId","show-select":"","single-select":"","return-object":""},scopedSlots:_vm._u([{key:"item.supplyUnit.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.supplyUnit.createdAt))+" ")]}}],null,true),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),(_vm.contractId)?_c('div',[_c('DisplayContainer',{attrs:{"label":'Anmerkungen'}},[_c('v-textarea',{ref:"memo",staticClass:"input-field",attrs:{"auto-grow":"","clearable":"","clear-icon":"mdi-delete","name":"memo-input","placeholder":"Anmerkungen","value":_vm.memo,"disabled":!_vm.claimed},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)],1):_vm._e()],1):_c('div',[_c('Loader')],1)]),(!_vm.isLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"primary","large":"","disabled":_vm.buttonsDisabled || !_vm.claimed},on:{"click":function($event){return _vm.completeTask(false)}}},[_vm._v(" "+_vm._s(_vm.selectedItem.length >= 1 ? 'Ausgewählte Supply Unit verwenden' : 'Neue Supply Unit generieren')+" ")]),_c('v-btn',{attrs:{"color":"secondary","large":"","disabled":_vm.buttonsDisabled || !_vm.claimed},on:{"click":function($event){return _vm.saveTask()}}},[_vm._v(" Speichern ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }