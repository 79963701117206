var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.isLoading)?_c('div',[(_vm.customerData)?_c('CustomerDetails',{attrs:{"customerId":_vm.customerId,"data":_vm.customerData,"parseSalutation":true,"title":"Kundeninformationen"}}):_vm._e(),_c('DisplayContainer',{attrs:{"label":"Vertragsinformationen"}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Vertragsnummer"}},[(
                  _vm.serviceContractId !== undefined && _vm.serviceContractId != null
                )?_c('div',[_vm._v(" "+_vm._s(_vm.serviceContractId)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Vertrag Unterschrieben"}},[(_vm.contractDates !== undefined && _vm.contractDates != null)?_c('div',[_vm._v(" "+_vm._s(new Date(_vm.contractDates.dateSigned).toLocaleDateString( 'de-De', { year: 'numeric', month: '2-digit', day: '2-digit' } ))+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Adresse"}},[_c('div',[_vm._v(" "+_vm._s(_vm.contractAddress.street)+" "+_vm._s(_vm.contractAddress.houseNumber)+_vm._s(_vm.contractAddress.houseNumberSupplement)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.contractAddress.zipCode)+" "+_vm._s(_vm.contractAddress.city)+" "),(_vm.contractAddress.district)?_c('span',[_vm._v(" - "+_vm._s(_vm.contractAddress.district))]):_vm._e()])])],1)]),_c('DisplayContainer',{attrs:{"label":"Projektinformationen"}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Projekt ID"}},[(_vm.projectId !== undefined && _vm.projectId != null)?_c('div',[_vm._v(" "+_vm._s(_vm.projectId)+" ")]):_c('div',[_vm._v(" - ")])]),_c('DataDisplay',{attrs:{"label":"Projekt Name"}},[(_vm.projectName !== undefined && _vm.projectName != null)?_c('div',[_vm._v(" "+_vm._s(_vm.projectName)+" ")]):_c('div',[_vm._v(" - ")])]),_c('DataDisplay',{attrs:{"label":"2G-Status"}},[(_vm.technologyState != undefined && _vm.technologyState != null)?_c('div',[_c('v-icon',{staticClass:"technology-state-icon",attrs:{"small":""}},[_vm._v(" mdi-state-machine ")]),_vm._v(_vm._s(_vm.technologyState)+" ")],1):_c('div',[_vm._v(" - ")])])],1)]),_c('DisplayContainer',{attrs:{"label":"Gebuchte Produkte"}},[_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.contractProducts}})],1)],1):_vm._e()]),(!_vm.isLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","large":"","disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.completeTask('accepted')}}},'v-btn',attrs,false),on),[_vm._v(" Technisch möglich ")])]}}],null,false,2570204481)},[_c('span',[_vm._v("Der Tarif ist technisch umsetzbar und der Auftrag wird normal prozessiert")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","large":"","disabled":_vm.buttonsDisabled},on:{"click":function($event){_vm.popupDowngrade = !_vm.popupDowngrade}}},'v-btn',attrs,false),on),[_vm._v(" Downgrade anbieten ")])]}}],null,false,1068129867)},[_c('span',[_vm._v("Der Tarif ist technisch nicht möglich, Aufgabe erstellen zum Downgrade anbieten")])])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"20vw"},model:{value:(_vm.popupDowngrade),callback:function ($$v) {_vm.popupDowngrade=$$v},expression:"popupDowngrade"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Aufgabe weitergeben ")]),_c('v-card-text',[_c('p',[_vm._v(" Die Aufgabe weitergeben, damit dem Kunden ein Downgrade angeboten werden kann? ")]),_c('v-spacer'),_c('div',{staticClass:"dialog-buttons"},[_c('v-btn',{staticClass:"align-center",attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.popupDowngrade = !_vm.popupDowngrade}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"align-center",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.completeTask('downgrade')}}},[_vm._v(" Ja ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }