var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('v-tabs',{attrs:{"background-color":"rgba(0,0,0,0.05)"}},[(_vm.potentialHcContractDuplicates.length > 0)?_c('v-tab',{on:{"click":function($event){_vm.hcTabSelected = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-home-outline ")]),_vm._v(" Hausanschlussauftrag ")],1):_vm._e(),(_vm.potentialServiceContractDuplicates.length > 0)?_c('v-tab',{on:{"click":function($event){_vm.hcTabSelected = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-web ")]),_vm._v(" Dienstauftrag ")],1):_vm._e()],1),_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.hcTabSelected && _vm.potentialHcContractDuplicates.length > 0),expression:"hcTabSelected && potentialHcContractDuplicates.length > 0"}],model:{value:(_vm.selectedHcContractIdTab),callback:function ($$v) {_vm.selectedHcContractIdTab=$$v},expression:"selectedHcContractIdTab"}},_vm._l((_vm.potentialHcContractDuplicates),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.hcTabSelected && _vm.potentialServiceContractDuplicates.length > 0
          ),expression:"\n            !hcTabSelected && potentialServiceContractDuplicates.length > 0\n          "}],model:{value:(_vm.selectedServiceContractIdTab),callback:function ($$v) {_vm.selectedServiceContractIdTab=$$v},expression:"selectedServiceContractIdTab"}},_vm._l((_vm.potentialServiceContractDuplicates),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-row',{staticStyle:{"margin-top":"20px"}},[(
              _vm.newContractData.installationAddress != null &&
                _vm.newContractData.invoiceAddress != null &&
                _vm.newContractData.products != null
            )?_c('v-col',[_c('DisplayContainer',[_c('div',{staticClass:"text-h4",staticStyle:{"color":"#209bd8","margin-bottom":"15px"}},[_vm._v(" Neuer Auftrag ")]),_c('DisplayContainer',{attrs:{"label":"Vertrag"}},[_c('DataDisplay',{attrs:{"label":"ID"}},[(_vm.hcTabSelected)?_c('div',[_vm._v(" "+_vm._s(_vm.newHcContractId)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.newServiceContractId)+" ")])])],1),(_vm.newContractData.invoiceAddress)?_c('CustomerDetails',{attrs:{"data":_vm.newContractData.invoiceAddress,"parseSalutation":true,"title":"Rechnungsadresse"}}):_vm._e(),(_vm.newContractData.installationAddress)?_c('CustomerDetails',{attrs:{"data":_vm.newContractData.installationAddress,"parseSalutation":true,"title":"Installationsadresse"}}):_vm._e(),_c('DisplayContainer',{attrs:{"label":"Gebuchte Produkte"}},[_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.newContractData.products}})],1)],1)],1):_vm._e(),(
              _vm.duplicateContractData.installationAddress != null &&
                _vm.duplicateContractData.invoiceAddress != null &&
                _vm.duplicateContractData.products != null
            )?_c('v-col',[_c('DisplayContainer',[_c('div',{staticClass:"text-h4",staticStyle:{"color":"#209bd8","margin-bottom":"15px"}},[_vm._v(" Potentielles Duplikat ")]),_c('DisplayContainer',{attrs:{"label":"Vertrag"}},[_c('DataDisplay',{attrs:{"label":"ID"}},[(_vm.hcTabSelected)?_c('div',[_vm._v(" "+_vm._s(_vm.potentialHcContractDuplicates[_vm.selectedHcContractIdTab])+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.potentialServiceContractDuplicates[ _vm.selectedServiceContractIdTab ])+" ")])])],1),(_vm.duplicateContractData.invoiceAddress)?_c('CustomerDetails',{attrs:{"data":_vm.duplicateContractData.invoiceAddress,"parseSalutation":true,"title":"Rechnungsadresse"}}):_vm._e(),(_vm.duplicateContractData.installationAddress)?_c('CustomerDetails',{attrs:{"data":_vm.duplicateContractData.installationAddress,"parseSalutation":true,"title":"Installationsadresse"}}):_vm._e(),_c('DisplayContainer',{attrs:{"label":"Gebuchte Produkte"}},[_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.duplicateContractData.products}})],1)],1)],1):_vm._e()],1)],1):_vm._e()]),(!_vm.isLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"primary","large":"","disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.completeTask(false)}}},[_vm._v(" Auftrag akzeptieren ")]),_c('v-btn',{attrs:{"color":"secondary","large":"","disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.completeTask(true)}}},[_vm._v(" Auftrag ablehnen ")])],1):_vm._e(),(_vm.isLoading)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }